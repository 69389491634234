export const SearchData = [
    "Iphone 15",
    "Macbook 14",
    "Imac",
    "Tv",
    "Hisense",
    "Hisense Tv",
    "Samsung",
    "Samsung Tv",
    "Sony Tv",
    "Sony Tv",
    "Iphone",
  ];