import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import Currency from "./components/Currency";
import CatalogSvg from "../../assets/SVG/CatalogSvg";
import HeaderCategorys from "./components/HeaderCategorys";
import Catalog from "./components/Catalog";
import MobileMenu from "./components/MobileMenu";
import CatalogMobile from "./components/CatalogMobile";
import SearchModal from "./components/SearchModal/newIndex";
import {
  getSearchInfo,
  setSearchInfo,
  setSearchWs,
} from "../../store/MainSlice";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { searchWsPage, createHistory } from "store/WsSearchSlice";
import Loader from "../Loader/newIndex";
import BackSearchSvg from "./components/BackSearchSvg";
export default function MobileHeader() {
  const [headLineWidth, setHeadLineWidth] = useState(0);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [requestToken, setRequestToken] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [closeSearchModal, setCloseSearchModal] = useState(false);
  // const [fixedHeader, setFixHeader] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentLanguage, searchInfo, WSsearchInfo, isLoading } = useSelector(
    ({ main }) => main
  );

  document.onscroll = function () {
    // setFixHeader(document.body?.parentNode.scrollTop >= 105);
    var pos = getVerticalScrollPercentage(document.body);
    setHeadLineWidth(Math.round(pos));
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (redirect && Object.keys(searchInfo || {}).length) {
      if (
        Object.keys(searchInfo?.category || {}).length &&
        Object.keys(searchInfo?.brand || {}).length
      ) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&b=${searchInfo?.brand?.id}&t=1&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.brand || {}).length) {
        window.location.href = `https://vlv.am/brand/${searchInfo?.brand?.slug}?item=brand&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.category || {}).length) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&lang=${currentLanguage}`;
      } else {
        window.location.href = `https://vlv.am/category/${searchText}?item=search&lang=${currentLanguage}`;
      }
    }
  }, [redirect, searchInfo]);
  useEffect(() => {
    if (loading && searchInfo) {
      handleSearch();
    }
  }, [searchInfo, loading]);
  useEffect(() => {
    setSearchText("");
    dispatch(setSearchInfo(null));
  }, [location]);

  const handleSearch = async () => {
    if (Object.keys(WSsearchInfo)?.length) {
      setCloseSearchModal(false);
      if (WSsearchInfo?.similar?.length) {
        await dispatch(createHistory(searchText))
        const categoryIds = [
          ...(WSsearchInfo?.similar?.map(category => category.id) || []),
          WSsearchInfo?.category?.id
        ].filter(Boolean);
        const encodedCategoryIds = btoa(JSON.stringify(categoryIds));

        navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/categories?ct=${encodedCategoryIds}`, { state: WSsearchInfo });
      } else {
        await dispatch(searchWsPage(WSsearchInfo, navigate, currentLanguage, searchText));
      }
      setLoading(false);
      setIsFocused(false);
      const inputElement = document.querySelector('input');
      if (inputElement) {
        inputElement.blur();
      }
    }
  };

  const onSearchChange = async (e) => {
    setSearchText(e.target.value);
    setLoading(false);

    if (e.target.value.length) {
      await dispatch(
        getSearchInfo(e.target.value, requestToken, setRequestToken)
      );
    } else {
      dispatch(setSearchInfo(null));
      dispatch(setSearchWs([]));
    }
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (searchText.length) {
        setLoading(true);
        if (searchInfo && Object.keys(searchInfo).length) {
          setCloseSearchModal(false);
          handleSearch();
        }
      }
    }
  };

  const handleClick = () => {
    setIsFocused(false)
  };
  return (
    <>
      <div onClick={() => setIsFocused(false)} id="modal-background" style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", backgroundColor: "white", zIndex: 1001, display: "none" }}></div>

      {loading && <Loader isLoading={loading} />}
      {window.innerWidth > 1024 ? (
        <Catalog openCatalog={openCatalog} setOpenCatalog={setOpenCatalog} />
      ) : (
        <CatalogMobile
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      )}
      <MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <header
        className={styles.container}
      // style={
      //   fixedHeader
      //     ? {
      //         // paddingTop: "290px",
      //       }
      //     : null
      // }
      >
        <div className={styles.wrapper}>
          <div
            className={styles.headLine}
            style={{ width: headLineWidth + "%" }}
          />
          <div className={styles.headerThreeBlocks}>
            <div className={styles.headerTop}>
              <div className={styles.leftContent}>
                {isFocused ? (
                  <div className={styles.wsCursor} onClick={() => handleClick()}>
                    <BackSearchSvg />
                  </div>
                ) : null}
                <Link to="/">
                  <img
                    className={`${styles.logo} ${isFocused ? styles.wsWidth50 : ''}`}
                    src={
                      currentLanguage === "hy"
                        ? require("../../assets/logo.png")
                        : "https://vlv.am/public/logos/header_ru.svg"
                    }
                    alt="VLV"
                  />
                </Link>
              </div>
              <div className={`${styles.rightContent} `}>
                <Currency shortBtn />
                <a href="tel:010349944">
                  <PhoneSvg />
                </a>
                <CatalogSvg
                  onClick={() => setOpenMenu(true)}
                  style={{ cursor: "pointer" }}
                  width="28"
                  height="19"
                  fill="#282828"
                />
              </div>
            </div>
            <div className={styles.headerBottom}>
              {!isFocused ? (
                <div
                  className={styles.catalogBtn}
                  onClick={() => setOpenCatalog(true)}
                >
                  <CatalogSvg />
                  <span>{langData.buttons.catalog[currentLanguage]}</span>
                </div>
              ) : null}

              <div className={styles.searchContainer}>
                {(window.innerWidth <= 992 && !isFocused) ? (
                  <input
                    placeholder={
                      langData.header.searchPlaceholder[currentLanguage]
                    }
                    className={`${styles.searchInput}  `}
                    onFocus={(e) => {
                      setIsFocused(true);
                      document.body.style.overflow = "hidden";
                      if (e.target.value.length) {
                        setCloseSearchModal(false);
                      }
                    }}
                    value={searchText}
                    onChange={onSearchChange}
                    onKeyDown={onKeyDown}
                  />
                ) : null}

                <span
                  className={`${styles.searchBtn} ${(window.innerWidth <= 992 && isFocused) ? styles.wsDisplayNone : ""}`}
                  onClick={() => {
                    if (searchText.length) {
                      setLoading(true);
                      handleSearch();
                    }
                  }}
                >
                  {langData.buttons.search[currentLanguage]}
                </span>
                <SearchModal
                  onSearchChange={onSearchChange}
                  setLoading={setLoading}
                  handleSearch={handleSearch}
                  onKeyDown={onKeyDown}
                  mobile={true}
                  isLoading={isLoading}
                  closeSearchModal={closeSearchModal}
                  setCloseSearchModal={setCloseSearchModal}
                  setIsFocused={setIsFocused}
                  isFocused={isFocused}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  getSearchInfo={getSearchInfo}
                  requestToken={requestToken}
                  setRequestToken={setRequestToken}
                />
              </div>
            </div>
          </div>
        </div>
        <HeaderCategorys />
      </header>
    </>
  );
}
