import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Drawer from "components/Drawer";
import { DATA } from "../../../../data";
import langData from "lang/data";
import checkBase64 from "helpers/checkBase64";

const NewCountryOfManufacture = ({
  data,
  onClearData,
  filters,
  sorting,
  setSorting,
  setManufactures,
  manufactures,
  attribute,
  setAtribute,
  currentLanguage, 
  handleSelectBrand, 
  activeBrands,
  handleSelectSorting,
  searchParams,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(false);
    onClearData({});
    setSorting(false);
    setManufactures(false);
    setAtribute(false);
  };

  useEffect(() => {
    if (attribute || sorting || manufactures) {
      setIsOpen(true);
    }
  }, [data?.name_en, attribute, sorting, manufactures]);

  return (
    <Drawer
      //   handleItem={
      //     manufactures ? handleManufactor : sorting ? handleSorting : handleItem
      //   }
      title={
        manufactures
          ? langData?.product.manufactor?.[currentLanguage]
          : sorting
          ? langData?.product.sorting?.[currentLanguage]
          : data?.["name_" + currentLanguage]
      }
      onClick={onClick}
      isOpen={isOpen}
      height="100dvh"
      filter="1"
      top="0px"
    >
      <div className={styles.country}>
        {sorting
          ? DATA?.map((item, key) => (
              <div className={styles.countryItem} key={key}>
                <p className={styles.checkboxWrapper}>
                  <span onClick={() => handleSelectSorting(item?.value)}>
                    {item?.["label_" + currentLanguage]}
                  </span>
                  <input
                    type="radio"
                    id={item?.value} 
                    name="radio-group"
                    checked={searchParams.get("s") == item?.value}
                  />
                  <label
                    for={item?.value}
                    onClick={() => handleSelectSorting(item?.value)}
                  />
                </p>
              </div>
            ))
          : manufactures
          ? filters?.brandList?.map((item) => (
              <div className={styles.countryItem}>
                <p className={styles.checkboxWrapper}>
                  <div className={styles.brandImg}>
                    <img
                      onClick={() => handleSelectBrand(item)}
                      src={
                        item?.logo
                          ? checkBase64(item?.logo)
                            ? "data:image/png;base64," + item?.logo
                            : process.env.REACT_APP_STORAGE_URL + item?.logo
                          : "https://vlv.am/public/frontend/default/img/brand_image.png"
                      }
                      alt="brand"
                    />
                  </div>
                  <input
                    type="checkbox"
                    id={item?.id}
                    name="radio-group"
                    checked={activeBrands?.includes(item.id)}
                   />
                  <label
                    for={item?.id}
                    onClick={() => handleSelectBrand(item)}
                  />
                </p>
              </div>
            ))
          : null}
      </div>
    </Drawer>
  );
};

export default NewCountryOfManufacture;
