import React from "react";
import styles from "./style.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getArticleItemData } from "../../store/MainSlice";
import langData from "../../lang/data";
import FromToPage from "../../components/FromToPage";
import Blog from "../Home/components/Blog";
import { getBlog } from "../../store/HomeSlice";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import { useState } from "react";
import LoadContent from "../../components/LoadContent";
import UseGetDate from "../../helpers/UseGetDate";

const ArticleItem = () => {
  const [showdropdwn, setShowdropdwn] = useState(false);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { currentLanguage, ArticleItemData } = useSelector(({ main }) => main);
  useEffect(() => {
    dispatch(getArticleItemData(slug));
    dispatch(getBlog());
  }, [slug]);

  //   let date = new Date(`${ArticleItemData?.post?.created_at}`)
  //     .toDateString()
  //     .split(" ");
  return (
    <>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: langData.header.menu.Blog[currentLanguage],
              value: "/article",
              active: false,
            },
            {
              label: ArticleItemData?.post?.["title_" + currentLanguage],
              value: "/article",
              active: true,
            },
          ]}
        />

        <div className={styles.aboutUsBlock}>
          <div className={styles.blogBanner}>
            {!ArticleItemData ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBaner}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  ArticleItemData?.post?.["image_url_" + currentLanguage]
                }
                alt="img"
              />
            )}
          </div>
          <div className={styles.descr}>
            {!ArticleItemData ? (
              <LoadContent containerClassName={styles.LoadContentdate} />
            ) : (
              <span>
                {UseGetDate(ArticleItemData?.post?.created_at, currentLanguage)}
              </span>
            )}
            <div
              className={styles.shareBlck}
              onClick={() => {
                setShowdropdwn(!showdropdwn);
              }}
            >
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.7392 0.0684608C9.93854 0.309321 9.41519 0.749245 9.06572 1.47496C8.73148 2.16923 8.68172 2.81606 8.89685 3.66909C8.91106 3.72544 8.09748 4.26172 6.80681 5.04685L4.6935 6.33246L4.31822 5.97605C3.82878 5.51131 3.32589 5.31061 2.63305 5.30354C0.684284 5.28379 -0.586749 7.39668 0.274129 9.22485C1.03513 10.8409 3.15166 11.2259 4.41425 9.97802L4.70552 9.69016L6.81285 10.9648C8.08183 11.7323 8.91085 12.2755 8.89685 12.3302C8.68124 13.1718 8.7319 13.8296 9.06572 14.5229C10.1925 16.8632 13.5574 16.299 13.9665 13.7012C14.2478 11.9149 12.7145 10.3283 10.9821 10.613C10.4178 10.7057 10.0484 10.8908 9.6457 11.282L9.30275 11.6153L7.18937 10.3364C5.7702 9.47747 5.08409 9.02407 5.10064 8.9561C5.27342 8.24571 5.27321 7.78218 5.09974 7.04177C5.08389 6.97416 5.7772 6.51612 7.18937 5.66152L9.30275 4.38254L9.6457 4.71584C11.136 6.16421 13.5933 5.27159 13.9595 3.14893C14.2288 1.58765 13.0865 0.0983454 11.5488 0.00562473C11.2521 -0.0122062 10.9223 0.0133277 10.7392 0.0684608ZM12.1974 0.917212C12.8422 1.20393 13.2962 1.94927 13.2962 2.72099C13.2962 4.51299 11.2136 5.39848 10.0039 4.12078C9.28861 3.36518 9.26479 2.20824 9.94829 1.41833C10.5581 0.713512 11.3451 0.538198 12.1974 0.917212ZM3.41334 6.19894C4.11819 6.5067 4.62733 7.40759 4.5383 8.18908C4.41542 9.26693 3.63019 9.99778 2.60477 9.98865C2.02884 9.98351 1.7257 9.8607 1.31246 9.46485C0.368448 8.56075 0.535393 6.95825 1.64387 6.28332C2.18981 5.95088 2.78084 5.9227 3.41334 6.19894ZM12.2276 11.5034C13.4562 12.106 13.6737 13.8154 12.6441 14.7772C11.5975 15.7549 9.93923 15.265 9.53752 13.8593C9.33975 13.1675 9.47127 12.5343 9.92351 12.0005C10.5377 11.2755 11.3887 11.092 12.2276 11.5034Z"
                  fill="#282828"
                ></path>
              </svg>
              <div
                className={
                  showdropdwn
                    ? styles.drpdwn_share + " " + styles.drpdwn_share_active
                    : styles.drpdwn_share
                }
              >
                <a
                  href={"https://wa.me/?text=" + window.location.href}
                  target="_blank"
                  className={styles.socialLinks}
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1441 2.23289C11.7942 0.850024 9.95034 0.0598145 7.97481 0.0598145C3.95791 0.0598145 0.665372 3.35236 0.665372 7.36926C0.665372 8.68627 1.02755 9.93744 1.65313 11.024L0.632446 14.8104L4.48472 13.8226C5.57126 14.3824 6.75657 14.7116 7.97481 14.7116C12.0246 14.7116 15.383 11.4191 15.383 7.40218C15.383 5.42666 14.527 3.61576 13.1441 2.23289ZM7.97481 13.4605C6.88828 13.4605 5.83466 13.1641 4.87983 12.6044L4.68227 12.4727L2.37749 13.0983L3.00308 10.8594L2.83845 10.6289C2.24579 9.64111 1.91654 8.52165 1.91654 7.36926C1.91654 4.04379 4.64935 1.31098 8.00774 1.31098C9.62109 1.31098 11.1357 1.93656 12.288 3.08895C13.4404 4.24134 14.1319 5.75591 14.1319 7.40218C14.1319 10.7277 11.3332 13.4605 7.97481 13.4605ZM11.3332 8.91675C11.1357 8.81798 10.2467 8.38995 10.082 8.32409C9.91741 8.25824 9.78571 8.22532 9.65401 8.42287C9.55523 8.5875 9.19306 9.01553 9.09428 9.14723C8.96258 9.24601 8.8638 9.27893 8.69917 9.18016C7.61264 8.65335 6.9212 8.22532 6.19684 7.00708C5.99929 6.67782 6.39439 6.71075 6.72365 6.01932C6.7895 5.88761 6.75657 5.78884 6.72365 5.69006C6.69072 5.59128 6.29562 4.7023 6.16392 4.34012C5.99929 3.97794 5.86759 4.01086 5.73589 4.01086C5.63711 4.01086 5.50541 4.01086 5.40663 4.01086C5.27493 4.01086 5.07738 4.04379 4.91275 4.24134C4.74812 4.4389 4.28717 4.86693 4.28717 5.75591C4.28717 6.67782 4.91275 7.53388 5.01153 7.66559C5.1103 7.76436 6.29562 9.60819 8.13944 10.3984C9.29183 10.9252 9.75279 10.9581 10.3454 10.8594C10.6747 10.8264 11.3991 10.4313 11.5637 10.0033C11.7283 9.57526 11.7283 9.21308 11.6625 9.14723C11.6295 9.04845 11.4978 9.01553 11.3332 8.91675Z"
                      fill="#282828"
                    ></path>
                  </svg>
                </a>
                <a
                  href={
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    window.location.href
                  }
                  target="_blank"
                  className={styles.socialLinks}
                >
                  <svg
                    width="12"
                    height="21"
                    viewBox="0 0 12 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6484 11.4167L11.1953 7.82294H7.71874V5.47919C7.71874 4.46356 8.18749 3.52606 9.74999 3.52606H11.3516V0.440125C11.3516 0.440125 9.90624 0.166687 8.53905 0.166687C5.68749 0.166687 3.81249 1.9245 3.81249 5.0495V7.82294H0.609366V11.4167H3.81249V20.1667H7.71874V11.4167H10.6484Z"
                      fill="#282828"
                    ></path>
                  </svg>
                </a>
                <a
                  href={
                    "https://telegram.me/share/url?url=" +
                    window.location.href +
                    "&text=Default+share+text"
                  }
                  target="_blank"
                  className={styles.socialLinks}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49028 0.0401592C3.97949 0.0401592 0.324771 3.72781 0.324771 8.20566C0.324771 12.7164 3.97949 16.3712 8.49028 16.3712C12.9681 16.3712 16.6558 12.7164 16.6558 8.20566C16.6558 3.72781 12.9681 0.0401592 8.49028 0.0401592ZM12.2438 5.60456C12.145 6.88865 11.6182 10.0495 11.3219 11.4653C11.2231 12.0909 10.9926 12.2884 10.7621 12.3213C10.3012 12.3543 9.93899 11.9921 9.47804 11.6958C8.75368 11.2348 8.35857 10.9385 7.66714 10.4775C6.844 9.95071 7.37081 9.65438 7.83177 9.19343C7.96347 9.06172 10.0378 7.15205 10.0707 6.98742C10.1036 6.9545 10.1036 6.88865 10.0378 6.8228C10.0048 6.78987 9.93899 6.8228 9.87314 6.8228C9.80729 6.8228 8.6549 7.58008 6.4489 9.09465C6.11965 9.32513 5.82332 9.4239 5.55991 9.4239C5.26358 9.4239 4.70385 9.25928 4.27582 9.12757C3.78194 8.96295 3.35391 8.86417 3.38684 8.60077C3.41976 8.43614 3.61731 8.30444 4.01242 8.13981C6.38305 7.0862 7.96347 6.42769 8.75368 6.09844C11.0255 5.1436 11.5194 4.97897 11.8157 4.97897C11.8816 4.97897 12.0462 4.97897 12.145 5.07775C12.1779 5.11067 12.2438 5.20945 12.2438 5.2753C12.2767 5.407 12.2767 5.50578 12.2438 5.60456Z"
                      fill="#282828"
                    ></path>
                  </svg>
                </a>
                <a
                  href={
                    "https://twitter.com/intent/tweet?text=" +
                    window.location.href
                  }
                  target="_blank"
                  className={styles.socialLinks}
                >
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.7079 4.25411C15.7079 4.41874 15.7079 4.55044 15.7079 4.71507C15.7079 9.2917 12.2508 14.5268 5.89615 14.5268C3.92062 14.5268 2.10972 13.9671 0.595154 12.9793C0.858557 13.0123 1.12196 13.0452 1.41829 13.0452C3.03163 13.0452 4.51328 12.4855 5.69859 11.5636C4.18402 11.5306 2.89993 10.5429 2.4719 9.16C2.70238 9.19292 2.89993 9.22585 3.13041 9.22585C3.42674 9.22585 3.75599 9.16 4.0194 9.09415C2.43898 8.76489 1.25366 7.38203 1.25366 5.70283V5.6699C1.71462 5.93331 2.27435 6.06501 2.83408 6.09793C1.87925 5.47235 1.28659 4.41874 1.28659 3.23342C1.28659 2.57491 1.45121 1.98226 1.74754 1.48838C3.45967 3.56268 6.02785 4.94555 8.89236 5.11017C8.82651 4.84677 8.79358 4.58337 8.79358 4.31996C8.79358 2.41029 10.3411 0.862793 12.2508 0.862793C13.2385 0.862793 14.1275 1.2579 14.786 1.94933C15.5433 1.7847 16.3006 1.48838 16.9591 1.09327C16.6957 1.91641 16.1689 2.57491 15.4445 3.00295C16.136 2.93709 16.8274 2.73954 17.42 2.47614C16.9591 3.16757 16.3664 3.76023 15.7079 4.25411Z"
                      fill="#282828"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <h1 className={styles.title}>
            {ArticleItemData?.post?.["title_" + currentLanguage]}
          </h1>
          <div className={styles.videoBlck}>
            {!ArticleItemData ? (
              <LoadContent containerClassName={styles.LoadContentVideo} />
            ) : (
              <element
                dangerouslySetInnerHTML={{
                  __html: ArticleItemData.post?.["content_" + currentLanguage],
                }}
              ></element>
            )}
          </div>
        </div>
      </div>
      <Blog btn={false} title={"LATEST NEWS"} />
      {ArticleItemData?.products?.length > 0 && (
        <ProductsWithSlide
          productsData={ArticleItemData?.products}
          title={langData.titles.ProductWithSlide[currentLanguage]}
        />
      )}
    </>
  );
};

export default ArticleItem;
