import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  get_stories: [],
  stories_title: {},
  imagesPath: "",
  loading: false,
  error: null,
};

export const getStoriesRequest = createAsyncThunk(
  "get_stories/getStoriesRequest",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/stories`,
        {
          params: {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const likeStoryRequest = createAsyncThunk(
  "get_stories/likeStoryRequest",
  async (storyId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/story/like/${storyId}`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        }
      );
      return { storyId, liked: response.data.likes };
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export const viewStoryRequest = createAsyncThunk(
  'get_stories/viewStoryRequest',
  async (storyId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/story/view/${storyId}`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const getStoriesSlice = createSlice({
  name: "get_stories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStoriesRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStoriesRequest.fulfilled, (state, { payload }) => {
        state.get_stories = payload.stories;
        state.stories_title = payload.stories_title;
        state.imagesPath = payload.imagesPath;
        state.loading = false;
      })
      .addCase(getStoriesRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(likeStoryRequest.fulfilled, (state, { payload }) => {
        const storyIndex = state.get_stories.findIndex(
          (story) => story.id === payload.storyId
        );
        if (storyIndex !== -1) {
          console.log(payload)
          state.get_stories[storyIndex].liked = payload.liked;
          state.get_stories[storyIndex].likes = state.get_stories[storyIndex].likes + 1;
        }
      })
      .addCase(likeStoryRequest.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(viewStoryRequest.fulfilled, (state, { payload }) => {
        const storyIndex = state.get_stories.findIndex(
          (story) => story.id === payload.storyId
        );
        if (storyIndex !== -1) {
          state.get_stories[storyIndex].views = state.get_stories[storyIndex].views + 1;
        }
      })
      .addCase(viewStoryRequest.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export default getStoriesSlice.reducer;
