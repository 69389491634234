import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "./request";
import axios from "axios";
import lang from "../lang/lang";

const initialState = {
  pending: false,
  isLoading: false,
  token: null,
  isAuthed: false,
  currentLanguage: "hy",
  currentCurrency: {
    label: "(֏) Դրամ",
    value: "amd",
    currency: "֏",
    convertRate: 1,
    id: 121,
  },
  brands: [],
  headerCategorys: [],
  menuData: [],
  showLoginModal: false,
  compares: [],
  favorites: [],
  shopCartCount: {
    count: 0,
    price: 0,
  },
  WSsearchInfo: [],
  shopCartPositions: null,
  shopCartProductAnimation: null,
  compareProductAnimation: null,
  heartProductAnimation: null,
  searchInfo: null,
  productInfo: null,
  showSubscribeModal: false,
  catalogSliders: null,
  compareSearch: [],
  similarProducts: [],
  cartSimilarProducts: [],
  recentProducts: [],
  cartPageProducts: [],
  blogPageData: null,
  ArticleData: null,
  aboutUsPageData: null,
  brand: null,
  creditPageData: null,
  deliveryData: null,
  blogItemData: null,
  ArticleItemData: null,
  favoritesPageproducts: [],
  profilePageInfo: [],
  comparePageProducts: [],
  errorSignUp: [],
};

// let process.env.REACT_APP_TOKEN;
// let CSRF_TOKEN;
export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    clearError: (store, action) => {
      return {
        ...store,
        errorSignUp: "",
      };
    },
    startLoading: (store, action) => {
      return {
        ...store,
        isLoading: true,
      };
    },
    setToken: (store, action) => {
      return {
        ...store,
        token: action.payload,
      };
    },
    setPending: (store, action) => {
      return {
        ...store,
        pending: action.payload,
      };
    },
    setCurrentLanguage: (store, action) => {
      return {
        ...store,
        currentLanguage: action.payload,
      };
    },
    setCurrentCurrency: (store, action) => {
      return {
        ...store,
        currentCurrency: {
          ...store.currentCurrency,
          ...action.payload,
        },
      };
    },
    setBrands: (store, action) => {
      return {
        ...store,
        brands: action.payload,
      };
    },
    setSearchWs: (store, action) => {
      return {
        ...store,
        isLoading: false,
        WSsearchInfo: action.payload,
      };
    },
    setMenuData: (store, action) => {
      return {
        ...store,
        menuData: action.payload,
      };
    },
    setHeaderCategorys: (store, action) => {
      return {
        ...store,
        headerCategorys: action.payload,
      };
    },
    setShowLoginModal: (store, action) => {
      return {
        ...store,
        showLoginModal: action.payload,
      };
    },

    setIsAuthed: (store, action) => {
      return {
        ...store,
        isAuthed: action.payload.auth,
        errorSignUp: action.payload.data,
      };
    },

    setFavorites: (store, action) => {
      return {
        ...store,
        favorites: action.payload,
      };
    },
    addFavorites: (store, action) => {
      return {
        ...store,
        favorites: [...store.favorites, action.payload],
      };
    },
    removeFavorites: (store, action) => {
      const filteredFavorites = store.favorites.filter((item) => {
        return item !== action.payload;
      });
      return {
        ...store,
        favorites: filteredFavorites,
      };
    },
    setCompares: (store, action) => {
      return {
        ...store,
        compares: action.payload,
      };
    },
    addCompares: (store, action) => {
      return {
        ...store,
        compares: [...store?.compares, action.payload],
      };
    },
    removeCompares: (store, action) => {
      const filteredCompares = store.compares.filter(
        (item) => item !== action.payload
      );

      let filteredComparesString = JSON.stringify(filteredCompares);
      filteredComparesString = filteredComparesString.slice(
        1,
        filteredComparesString.length - 1
      );

      document
        .querySelector('meta[name="compare_d"]')
        .setAttribute("content", filteredComparesString);

      return {
        ...store,
        compares: filteredCompares,
      };
    },
    setShopCartCount: (store, action) => {
      return {
        ...store,
        shopCartCount: {
          count: action.payload.count,
          price: action.payload.price,
        },
      };
    },
    addShopCartCount: (store, action) => {
      if (typeof action.payload == "object") {
        return {
          ...store,
          shopCartCount: {
            count: store.shopCartCount.count + action.payload.count,
            price: store.shopCartCount.price + action.payload.price,
          },
        };
      } else {
        return {
          ...store,
          shopCartCount: {
            count: store.shopCartCount.count + 1,
            price: store.shopCartCount.price + action.payload,
          },
        };
      }
    },
    setShopCartPositions: (store, action) => {
      return {
        ...store,
        shopCartPositions: action.payload,
      };
    },
    setShopCartProductAnimation: (store, action) => {
      return {
        ...store,
        shopCartProductAnimation: action.payload,
      };
    },
    setCompareProductAnimation: (store, action) => {
      return {
        ...store,
        compareProductAnimation: action.payload,
      };
    },
    setHeartProductAnimation: (store, action) => {
      return {
        ...store,
        heartProductAnimation: action.payload,
      };
    },
    setSearchInfo: (store, action) => {
      return {
        ...store,
        isLoading: false,
        searchInfo: action.payload,
      };
    },
    setProductInfo: (store, action) => {
      return {
        ...store,
        productInfo: action.payload,
      };
    },
    setShowSubscribeModal: (store, action) => {
      return {
        ...store,
        showSubscribeModal: action.payload,
      };
    },
    setCatalogSliders: (store, action) => {
      return {
        ...store,
        catalogSliders: action.payload,
      };
    },
    setCompareSearch: (store, action) => {
      return {
        ...store,
        isLoading: false,
        compareSearch: action.payload,
      };
    },
    setSimilarProducts: (store, action) => {
      return {
        ...store,
        similarProducts: action.payload,
      };
    },
    setCartSimilarProducts: (store, action) => {
      return {
        ...store,
        cartSimilarProducts: action.payload,
      };
    },
    setRelatedProducts: (store, action) => {
      return {
        ...store,
        relatedProducts: action.payload,
      };
    },
    setRecentProducts: (store, action) => {
      return {
        ...store,
        recentProducts: action.payload,
      };
    },
    setCartPageProducts: (store, action) => {
      return {
        ...store,
        cartPageProducts: action.payload,
        isLoading:true
      };
    },
    setBlogtPageData: (store, action) => {
      return {
        ...store,
        blogPageData: action.payload,
      };
    },
    setArticletPageData: (store, action) => {
      return {
        ...store,
        ArticleData: action.payload,
      };
    },
    setAboutUsData: (store, action) => {
      return {
        ...store,
        aboutUsPageData: action.payload,
      };
    },
    setBrandData: (store, action) => {
      return {
        ...store,
        brand: action.payload,
      };
    },
    setCreditPageData: (store, action) => {
      return {
        ...store,
        creditPageData: action.payload,
      };
    },
    setDeliveryData: (store, action) => {
      return {
        ...store,
        deliveryData: action.payload,
      };
    },
    setBlogItemData: (store, action) => {
      return {
        ...store,
        blogItemData: action.payload,
      };
    },
    setArticleItemData: (store, action) => {
      return {
        ...store,
        ArticleItemData: action.payload,
      };
    },
    setFavoritesPageproducts: (store, action) => {
      return {
        ...store,
        favoritesPageproducts: action.payload,
      };
    },
    setProfilePageInfo: (store, action) => {
      return {
        ...store,
        profilePageInfo: action.payload,
      };
    },

    setComparePageProducts: (store, action) => {
      return {
        ...store,
        comparePageProducts: action.payload,
      };
    },
  },
});

export const postLang = (lang) => () => {
  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "locale/json", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      lang,
    })
    .catch((err) => {
      console.warn("Error: postLang", err);
    });
};
export const getTranlations = () => async (dispatch) => {
  await axiosInstance
    .post("/get-translations", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      lang.en = JSON.parse(response.data.en);
      lang.ru = JSON.parse(response.data.ru);
      lang.hy = JSON.parse(response.data.hy);
    })
    .catch((err) => {
      console.warn("Error: getTranlations", err);
    });
};

export const getJobsPageInfo = () => async (dispatch) => {
  await axiosInstance
    .post("/job", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      // dispatch(setJobText(response.data));
    })
    .catch((err) => {
      console.warn("Error: getJobsPageInfo", err);
    });
};

export const UserLogin =
  ({ login, password }) =>
    (dispatch) => {
      axiosInstance
        .post("/user/login", {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
          username: login,
          user_password: password,
        })
        .then((response) => {
          if (response.data.code === 3) {
            localStorage.setItem("access_token", response.data.token);
            dispatch(setIsAuthed({ auth: true }));
            dispatch(setShowLoginModal(false));
          }
        })
        .catch((err) => {
          console.warn("Error: Login", err);
        });
    };
export const UserSignUp = (data, callBack) => (dispatch) => {
  axiosInstance
    .post("/user/register", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data.email,
      phone: data?.phone,
      user_password: data?.password,
      password_confirmation: data?.password2,
    })
    .then((response) => {
      if (response.data.code === 3) {
        dispatch(setIsAuthed({ auth: true, data: response }));
        // dispatch(setShowLoginModal(false));
        // dispatch(setError());
        callBack();
      }
    })
    .catch((err) => {
      dispatch(
        setIsAuthed({ auth: false, data: err?.response?.data?.message })
      );

      console.warn("Error: SignUp", err);
    });
};
export const getBlogItemData = (slug) => async (dispatch) => {
  dispatch(setPending(true));
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + `api/blog/${slug}`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setBlogItemData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getBlogItemData", err);
    })
    .finally(() => {
      dispatch(setPending(false));
    });
};
export const getArticleItemData = (slug) => async (dispatch) => {
  dispatch(setPending(true));
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + `api/article/${slug}`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setArticleItemData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getBlogItemData", err);
    })
    .finally(() => {
      dispatch(setPending(false));
    });
};
export const getHaedaerCategorys = () => async (dispatch) => {
  await axiosInstance
    .post("/header-category", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(
        setHeaderCategorys([...response.data.Category, ...response.data.Brand])
      );
    })
    .catch((err) => {
      console.warn("Error: getHaedaerCategorys", err);
    });
};
export const getMenuData = () => async (dispatch) => {
  await axiosInstance
    .post("/menu-select-test", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setMenuData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getMenuData", err);
    });
};

export const addCompare = (data) => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  let compare_d = document.querySelector('meta[name="compare_d"]')?.content;
  if (document.querySelector('meta[name="compare_d"]').content) {
    document
      .querySelector('meta[name="compare_d"]')
      .setAttribute("content", compare_d + "," + data.product_sku_id);
  } else {
    document
      .querySelector('meta[name="compare_d"]')
      .setAttribute("content", data.product_sku_id);
  }

  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "compare", {
      login: "VlvShopA",
      password: "oL0rG6vQ6k",
      token: "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3",
      product_sku_id: data.product_sku_id,
      data_type: data.data_type,
      product_type: "product",
      _token,
    })
    .then((response) => { })
    .catch((err) => {
      console.warn("Error: addCompare", err);
    });
};
export const addWishList = (data) => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "wishlist/store", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      type: "product",
      seller_id: 1,
      shipping_method_id: 0,
      _token: _token,
      ...data,
    })
    .then((response) => {
      if (!response.data.error) {
      } else {
        console.warn("wishlist error", response.data);
      }
    })
    .catch((err) => {
      console.warn("Error: addWishList", err);
    });
};
export const addCardStore = (data) => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  const session_id = document.querySelector('meta[name="s_d"]')?.content;
 
  axiosInstance
    .post(
      process.env.REACT_APP_SERVER_URL + "cart/store",
      {
        token: process.env.REACT_APP_TOKEN,
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        _token: _token,
        session_id,
        ...data,
      },
      {
        headers: {
          common: {
            "X-Requested-With": "XMLHttpRequest",
            withCredentials: true,
          },
        },
      }
    )
    .catch((err) => {
      console.warn("Error: addCardStore", err);
    });
};
export const getSearchInfo =
  (keyword, requestToken, setRequestToken, compare) => (dispatch) => {
    if (requestToken) {
      // Если запрос уже выполняется, отмените его
      requestToken?.cancel("Request canceled");
    }
    dispatch(startLoading());

    const source = axios?.CancelToken?.source();
    setRequestToken?.(source);
    const sendData = {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      keyword: keyword,
    };
    if (compare) {
      sendData.compare = 1;
      axiosInstance
        .post("get-search-info", sendData, {
          cancelToken: source?.token,
        })
        .then((response) => {
          if (compare) {
            dispatch(setCompareSearch(response.data));
          } else {
            dispatch(setSearchInfo(response.data));
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.warn("getSearchInfo", err.message);
          } else {
            console.warn("Error: addCardStore", err);
            // Обработка других ошибок
          }
        });
    } else {

      axios.post('https://v1.vlv.am/api/search', sendData, {
        cancelToken: source?.token,
      })
        .then((response) => {
          if (compare) {
            dispatch(setCompareSearch(response.data));
          } else {
            dispatch(setSearchInfo(response.data));
          }
          dispatch(setSearchWs(response.data));
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.warn("getSearchInfo", err.message);
          } else {
            console.warn("Error: addCardStore", err);
          }
        });
    }
  };
export const getProductInfo = (id) => (dispatch) => {
  axiosInstance
    .post(`product-info/${id}`, {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
    })
    .then((response) => {
      const productData = response.data;

      let updatedGalleryImages = [...productData.product.gallary_images_api];

      if (productData.product.video_link) {

        const videoId = productData.product.video_link.split("v=")[1];
        updatedGalleryImages.push({
          id: Math.random(),
          product_id: productData.product.id,
          images_source: null,
          thum_image_sourc: null,
          video_link: `https://www.youtube.com/embed/${videoId}`,
          video_image: productData.product.video_image,
          media_id: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        });
      }

      productData.product.gallary_images_api = updatedGalleryImages;

      dispatch(setProductInfo(productData));
    })
    .catch((err) => {
      window.location.href = "https://vlv.am/404";

      console.warn("Error: getProductInfo", err);
    });
};

export const userSubscribe = (email) => (dispatch) => {
  const data = new FormData();
  data.append("token", process.env.REACT_APP_TOKEN);
  data.append("login", process.env.REACT_APP_LOGIN);
  data.append("password", process.env.REACT_APP_PASSWORD);
  data.append("email", email);

  axiosInstance
    .post("/footer-subscribe", data)
    .then((response) => {
      dispatch(setShowSubscribeModal(true));
    })
    .catch((err) => {
      console.warn("Error: getProductInfo", err);
    });
};
export const getConvertRate = (id) => (dispatch) => {
  axiosInstance
    .get("/Localcurrency", {
      params: {
        currency: id,
        token: process.env.REACT_APP_TOKEN,
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
      },
    })
    .then((response) => {
      dispatch(
        setCurrentCurrency({
          convertRate: response.data.convert_rate,
        })
      );
    })
    .catch((err) => {
      console.warn("Error: getConvertRate", err);
    });
};
export const getWishlistCartCount = () => (dispatch) => {
  const session_id = document.querySelector('meta[name="s_d"]')?.content; //"rgR8OSSoJcf5NcBp803lVxQ7DT1KcUldyeNK5PB3";
  const compare_d = document.querySelector('meta[name="compare_d"]')?.content;
  if (compare_d && compare_d?.length) {
    dispatch(setCompares(JSON.parse("[" + compare_d + "]")));
  }
  if (session_id) {
    axiosInstance
      .get(process.env.REACT_APP_SERVER_URL + "wishlist/count/" + session_id)
      .then((response) => {
        if (response?.data?.wishlist?.[0]?.ids)
          dispatch(setFavorites(response.data.wishlist[0].ids));
        if (response.data?.cart?.[0]?.qty)
          dispatch(
            setShopCartCount({
              price: response.data.cart[0].subtotal,
              count: response.data.cart[0].qty,
            })
          );
      })
      .catch((err) => {
        console.warn("Error: getWishlistCartCount", err);
      });
  }
};
export const forgotPassword = (email, callBack = () => { }) => {
  let payload;
  if (email.includes("@")) {
    payload = {
      email: email,
    };
  } else {
    payload = {
      email_phone: email,
    };
  }
  return (dispatch) => {
    const _token = document.querySelector('meta[name="_token"]')?.content;
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(process.env.REACT_APP_SERVER_URL + "password/email", {
          token: process.env.REACT_APP_TOKEN,
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          _token,
          ...payload,
        })
        .then((response) => {
          if (response.data.code === "0") {
            callBack();
            resolve(response);
          } else {
            reject(response.data.message || "Unknown error");
          }
        })
        .catch((err) => {
          reject(err?.response?.data?.message || "An error occurred");
        });
    });
  };
};
export const getCatalogSliders = () => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post("get-category-slider-images", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      _token,
    })
    .then((response) => {
      dispatch(setCatalogSliders(response.data));
    })
    .catch((err) => {
      console.warn("Error: forgotPassword", err);
    });
};
export const getSimilarProducts = (id) => (dispatch) => {
  dispatch(setSimilarProducts([]));
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post(`similar-products/${id}`, {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,

      _token,
    })
    .then((response) => {
      dispatch(setSimilarProducts(response.data));
    })
    .catch((err) => {
      console.warn("Error: getSimilarProducts", err);
    });
};
export const getCartSimilarProducts = () => (dispatch) => {
  const session_id = document.querySelector('meta[name="s_d"]')?.content;
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post("cart/similar-products", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      session_id,
      _token,
    })
    .then((response) => {
      dispatch(setCartSimilarProducts(response.data));
    })
    .catch((err) => {
      console.warn("Error: getCartSimilarProducts", err);
    });
};
export const getRelatedProducts = (id) => (dispatch) => {
  dispatch(setRelatedProducts([]));
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post(`related-products/${id}`, {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      _token,
    })
    .then((response) => {
      dispatch(setRelatedProducts(response.data));
    })
    .catch((err) => {
      console.warn("Error: getRelatedProducts", err);
    });
};
export const getRecentProducts = (id) => (dispatch) => {
  dispatch(setRecentProducts([]));
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post(`recent-products/${id}`, {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      _token,
    })
    .then((response) => {
      dispatch(setRecentProducts(response.data.products));
    })
    .catch((err) => {
      console.warn("Error: getRelatedProducts", err);
    });
};
export const guestAddressStore =
  (data, callBack = () => { }, errorCallBack = () => { }) =>
    (dispatch) => {
      const _token = document.querySelector('meta[name="_token"]')?.content;
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      axiosInstance
        .post(process.env.REACT_APP_SERVER_URL + "checkout/guest-address/store", {
          token: process.env.REACT_APP_TOKEN,
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          _token,
          session_id,
          deliveryType: "on",
          km: 0,
          fast_delivery: "",
          city: "",
          state: "",
          address: data?.map_address,
          address_id: 0,
          ...data,
        })
        .then(callBack)
        .catch((err) => {
          errorCallBack();
          console.warn("Error: guestAddressStore", err);
        });
    };

export const postCreditModal =
  (data, callBack = () => { }, errorCallBack = () => { }) =>
    (dispatch) => {
      const _token = document.querySelector('meta[name="_token"]')?.content;
      const session_id = document.querySelector('meta[name="s_d"]')?.content;
      axiosInstance
        .get(process.env.REACT_APP_SERVER_URL + "checkout8", {
          params: {
            token: process.env.REACT_APP_TOKEN,
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            step: "complete_order",
            shipping_method: 1,
            delivery_type: 1,
            // number_of_package: 1,
            ...data,
            lang: "hy",
            _token,
            session_id
          },
        })
        .then(callBack)
        .catch((err) => {
          errorCallBack();
          console.warn("Error: postCreditModal", err);
        });
    };
export const postCartCoupon = (data) => (dispatch) => {
  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "checkout/coupon-apply", {
      params: {
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        coupon_code: +data.coupon_code,
        shopping_amount: data.shopping_amount,
      },
    })
    .then((response) => {
      if (response.data === "ok") {
        window.location.reload();
      }
    })
    .catch((err) => {
      return err;
    });
};
export const postCreditModal2 = (data, callBack) => (dispatch) => {
  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "checkout-credit-email", data, {
      headers: {
        "Content-Type": "multipart/form-data", //
      },
    })
    .then(callBack)
    .catch((err) => {
      console.warn("Error: postCreditModal", err);
    });
};
const session_id = document.querySelector('meta[name="s_d"]')?.content;
const _token = document.querySelector('meta[name="_token"]')?.content;

export const getCartPageProducts = () => (dispatch) => {
  axiosInstance
    .post("cart/get-products", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      session_id,
      _token: _token,
    })
    .then((res) => {
      dispatch(setCartPageProducts(res.data));
    })
    .catch((err) => {
      console.warn("Error: getCartPageProducts", err);
    });
};
export const setCartPageUpdateQty = (data) => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;

  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "cart/update-qty", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      _token,
      ...data,
    })
    .catch((err) => {
      console.warn("Error: getCartPageUpdateQty", err);
    });
};
export const setCartPageDelete = (data) => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post("cart/delete", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      _token,
      ...data,
    })
    .catch((err) => {
      console.warn("Error: getCartPageUpdateQty", err);
    });
};
export const getBrand = () => (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance //
    .post("/brand", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setBrands(response.data?.Brand));
    })
    .catch((err) => {
      console.warn("Error: getBrand", err);
    });
};
export const getBlogPageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(`/blog-all`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setBlogtPageData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getBlogPageData", err);
    });
};
export const getArticlePageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(`/article-all`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setArticletPageData(response.data));
    })
    .catch((err) => {
      console.warn("Error: setArticletPageData", err);
    });
};
export const getAbouUsPageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post("/about-us", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setAboutUsData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getAbouUstPageData", err);
    });
};
export const getBrandPageInfo = (brand) => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(`/brand/${brand}`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setBrandData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getBrandPageInfo", err);
    });
};
export const getDeliveryPageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post(`/delivery`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setDeliveryData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getDeliveryPageData", err);
    });
};
export const getProfilePageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  // const _token = "hyuY6HEMDKnL0EAZyGqZb6q06bYI144tSigjCeG5";

  await axiosInstance
    .post(`/profile/dashboard`, {
      // .post(`https://vlv.am/api/profile/dashboard`, {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
      access_token: localStorage.getItem("access_token"),
    })
    .then((response) => {
      dispatch(setProfilePageInfo(response.data));
    })
    .catch((err) => {
      console.warn("Error: profilePageInfo", err);
    });
};
export const getCreditPageData = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  await axiosInstance
    .post("/credit-page-info", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      _token,
    })
    .then((response) => {
      dispatch(setCreditPageData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getCreditPageInfo", err);
    });
};
export const getFavoritesPageproducts = () => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  const session_id = document.querySelector('meta[name="s_d"]')?.content;
  await axiosInstance
    .post("/wishlist-products", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      session_id,
      _token,
    })
    .then((response) => {
      dispatch(setFavoritesPageproducts(response.data));
      if (!response.data?.error && response.data?.products?.length) {
      }
      let productIds = response.data?.products.map((product) => product.id);
      dispatch(setFavorites(productIds));
    })
    .catch((err) => {
      console.warn("Error: getFavoritesPageproducts", err);
    });
};
export const onClickInstallingBtn = (price) => () => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  axiosInstance
    .post(process.env.REACT_APP_SERVER_URL + "price/ajax", {
      price: price,
      _token,
    })

    .catch((err) => {
      console.warn("Error: onClickInstallingBtn", err);
    });
};
export const getComparePageProducts = (compare_id) => async (dispatch) => {
  const _token = document.querySelector('meta[name="_token"]')?.content;
  const session_id = document.querySelector('meta[name="s_d"]')?.content;

  await axiosInstance
    .post("/compare-products", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      session_id,
      compare_id,
      _token,
    })
    .then((response) => {
      dispatch(setComparePageProducts(response.data.products));
    })
    .catch((err) => {
      console.warn("Error: getComparePageProducts", err);
    });
};

export const {
  clearError,
  setToken,
  setPending,
  setCurrentCurrency,
  setCurrentLanguage,
  setHeaderCategorys,
  setMenuData,
  setShowLoginModal,
  setIsAuthed,
  addFavorites,
  removeFavorites,
  setCompares,
  addCompares,
  removeCompares,
  addShopCartCount,
  setShopCartPositions,
  setShopCartProductAnimation,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setSearchInfo,
  setProductInfo,
  setShowSubscribeModal,
  setFavorites,
  setShopCartCount,
  setCatalogSliders,
  setCompareSearch,
  setSimilarProducts,
  setCartSimilarProducts,
  setBrands,
  setRelatedProducts,
  setRecentProducts,
  setCartPageProducts,
  setBlogtPageData,
  setAboutUsData,
  setBrandData,
  setCreditPageData,
  setDeliveryData,
  setBlogItemData,
  setFavoritesPageproducts,
  setProfilePageInfo,
  setComparePageProducts,
  setSearchWs,
  setArticleItemData,
  startLoading,
  setArticletPageData
} = mainSlice.actions;
export default mainSlice.reducer;
