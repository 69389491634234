import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import Title from "./components/Title/newTitle";
import { Link } from "react-router-dom";
import lang from "lang/lang";
import { searchWsPageMan,searchWsPageBanners } from "store/WsSearchSlice";
import BanerSlider from "components/BanerSlider";
import LoadContent from "components/LoadContent";
import ProductList from "./components/productList/newIndex";
import Attributes from "./components/Attribute/newIndex";
import { selectMain } from "store/selector";
import Brands from "components/Brand";
import Brand from "screens/SearchResult/components/Category/index";

const NewCategory = () => {
  const { filters, isLoading,banners } = useSelector(
    ({ WsSearchSlice }) => WsSearchSlice
  );
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(selectMain);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSalesDiscountPage, setIsSalesDiscountPage] = useState(false);

  let { categoryId, "*": anyPath } = useParams();

  let location = useLocation(); 
  const pageFromUrl = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(pageFromUrl);
  const scrollElement = useRef(null);
  const [recordPage, setRecordPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
     setIsSalesDiscountPage(location.pathname.includes("/sales/discount"));
  }, [location.pathname]);
  useEffect(() => {
    const paramsArray = Array.from(searchParams.entries());
    const filteredParamsArray = paramsArray.filter(
      ([_, value]) => value !== null && value !== ""
    );

    const filteredInfo = Object.fromEntries(filteredParamsArray);
    if(isSalesDiscountPage){
      dispatch(searchWsPageBanners())
    }
 
    
    dispatch(
      searchWsPageMan(categoryId, filteredInfo, navigate, false, false, anyPath,isSalesDiscountPage,currentLanguage)
    );
  }, [categoryId, dispatch, navigate, searchParams, anyPath,isSalesDiscountPage]);
 
  const handleMoreProducts = (number) => {
    setCurrentPage(1);
    searchParams.set("p", number);
    setSearchParams(searchParams);
  };
  const handleMoreProduct = () => {
    setCurrentPage(1);
    searchParams.delete("page");
    searchParams.set("p", filters?.products?.length + recordPage);
    setSearchParams(searchParams);
  };
  useEffect(() => {
    scrollElement?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }, [setSearchParams]);
  useEffect(() => {
    if (!searchParams.size) {
      setCurrentPage(1);
      window.scrollTo(0, 0);
    }
  }, [categoryId, searchParams.size]);
 
  
  return typeof filters === "object" ? (
    <div className="wrapper" style={{ overflowX: "visible" }}>
      <div className={styles.category}>
        <Title
          category={filters?.category}
          styles={styles}
          countProduct={filters?.productCount}
          isSalesDiscountPage={isSalesDiscountPage}
        />

        <div className={styles.categorySlider}>
          <BanerSlider
            bannerSliders={filters?.category?.slider_images ?? banners?.banners}
            containerStyle={{
              margin: 0,
              padding: 0,
              position: "relative",
            }}
            loop
            loading={isLoading}
          />
        </div>

        {isLoading ? (
          <LoadContent containerClassName={styles.categoryBrandLoad} />
        ) : (
          <>
            {filters?.category?.children?.length > 1 && (
              <div className={styles.categoryBrand}>
                {!!filters?.category?.children?.length &&
                  filters?.category?.children?.map((item, index) => (
                    <Link
                      to={`${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/${item?.slug}${
                        filters?.brand ? `?b=${filters?.brand?.id}` : ""
                      }`}
                    >
                      <div key={index} className={styles?.categoryBrandItem}>
                        <div className={styles.categoryItemTop}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item?.image?.image
                            }
                            alt="product"
                          />
                        </div>
                        <div className={styles.categoryItemBottom}>
                          <span>{item?.["name_" + currentLanguage]}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </>
        )}
        {/* {filters?.brandList && !isSalesDiscountPage ? (
          <Brands innerBrands={filters?.brandList} type={true} />
        ) : null} */}
        <div className={styles.example} ref={scrollElement} />
        <Attributes
          styles={styles}
          setCurrentPage={setCurrentPage}
          filters={filters}
          loading={isLoading}
          currentLanguage={currentLanguage}
        />
        {filters?.products ? (
          <ProductList
            styles={styles}
            category={filters}
            loading={isLoading}
            currentPage={currentPage}
            handleMoreProducts={handleMoreProducts}
            handleMoreProduct={handleMoreProduct}
            recordPage={recordPage}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            setRecordPage={setRecordPage}
            handlePagination={(e) => {
              searchParams.set("page", e);
              setSearchParams(searchParams);
              setCurrentPage(e);
            }}
          />
        ) : filters?.category ? (
          <span>{lang[currentLanguage].product_not_found}</span>
        ) : null}
      </div>
    </div>
  ) : filters ?( 
      <Brand WsSlug={filters}/>
  ) :null;
};

export default NewCategory;
