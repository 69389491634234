import React, { Fragment } from "react";
import styles from "./style.module.css";
import HomeSvg from "./assets/HomeSvg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import langData from "lang/data";
export default function FromToPage({ pages = [], langs, type = false }) {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  return (
    <div className={styles.container}>
      <Link to={`${currentLanguage !== "hy" ? "/" + currentLanguage : "/"}`} className={styles.homeBtn}>
        <HomeSvg />
      </Link>
      {type ? (
        <Fragment key={0}>
          <span className={styles.line} /> 
            <span
              className={styles.title}
              style={{ color: "#d10000", fontWeight: "bold" }}
            >
             {langData.buttons.catalog[currentLanguage]}
            </span>
          
        </Fragment>
      ) : pages.map((item, index) => {
        return (
          <Fragment key={index}>
            <span className={styles.line} />
            <Link onClick={item?.onClick} to={item?.value}>
              <span
                className={styles.title}
                style={
                  item.active ? { color: "#d10000", fontWeight: "bold" } : {}
                }
              >
                {item.label}
              </span>
            </Link>
          </Fragment>
        );
      })}

    </div>
  );
}
