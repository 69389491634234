import React, { useRef } from "react";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../components/LoadContent/index";
import styles from "./style.module.css";
import Pageination from "../../components/Pageination";
import langData from "../../lang/data";
import { useState } from "react";
import { useEffect } from "react";
import { getArticlePageData } from "../../store/MainSlice";
import { Link } from "react-router-dom";
import UseGetDate from "../../helpers/UseGetDate";

export default function Article() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { currentLanguage, ArticleData } = useSelector(({ main }) => main);
  const [recordsPerPage] = useState(12);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = ArticleData?.Article?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(
    ArticleData && ArticleData?.Article?.length / recordsPerPage
  );
  const titleRef = useRef();

  useEffect(() => {
    dispatch(getArticlePageData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   titleRef.current?.scrollIntoView({
  //     block: "start",
  //   });
  // }, [currentPage]);

  const bannerImg = {
    hy: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6a0d4c.png",
    ru: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6b788e.png",
    en: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6ce5a6.png",
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.footer.Information.article[currentLanguage],
            value: "/article",
            active: true,
          },
        ]}
      />
      {/* <div className={styles.creditBanner}>
        <img
          className={styles.creditBannerImg}
          src={bannerImg[currentLanguage]}
          alt="img"
        />
      </div> */}
      <div className={styles.ArticleSection}>
        <h1 ref={titleRef}>{langData.header.menu.Article?.[currentLanguage]}</h1>
        <div className={styles.main}>
          {!ArticleData
            ? Array.from({ length: 12 })?.map((_, index) => (
                <div
                  className={styles.ArticleItem}
                  key={index}>
                  <LoadContent containerClassName={styles.loadContentImg} />
                  <LoadContent containerClassName={styles.loadContentTitle} />
                  <LoadContent containerClassName={styles.loadContentDate} />
                </div>
              ))
            : currentRecords?.map((item, index) => {
                return (
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/article/${item.slug}`}
                    className={styles.ArticleItem}
                    key={index}>
                    <img
                      className={styles.ArticleItemImg}
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        item["image_url_" + currentLanguage]
                      }
                      alt={item["title_" + currentLanguage]}
                    />
                    <h4 className={styles.ArticleItemTitle}>
                      {item?.["title_" + currentLanguage]?.substring(0, 45)}
                      ...
                    </h4>

                    <span className={styles.ArticleItemDate}>
                      {UseGetDate(item.created_at, currentLanguage)}
                    </span>
                  </Link>
                );
              })}
        </div>
        <div className={styles.pageination}>
          <Pageination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
