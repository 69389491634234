import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import Payment from "./components/payment";
import { useDispatch, useSelector } from "react-redux";
import CartForm from "./components/CartForm";
import CartProduct from "./components/CartProduct";
import {
  getCartPageProducts,
  getCartSimilarProducts,
  getProfilePageData,
  guestAddressStore,
  postCartCoupon,
  postCreditModal,
  setCartPageDelete,
  setCartPageProducts,
} from "../../../../store/MainSlice";
import lang from "../../../../lang/lang";
import langData from "../../../../lang/data";
import UseCalcPrice from "../../../../helpers/UseCalcPrice";
import axiosInstance from "../../../../store/request";
import Agreement from "./components/Agreement";
import CartLoader from "../CartLoader";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import { Link, useNavigate } from "react-router-dom";
import CartRelatedProducts from "./components/CartRelatedProducts";
import { toast } from "react-toastify";
import ThanksModal from "../../../../components/CreditMobal/components/ThanksModal";
import isValidPhone from "../../../../helpers/checkPhone";
import isValidEmail from "../../../../helpers/checkEmail";
import LoginCart from "../LoginCart";
import PersonalCart from "../PersonalCart";
import { getUserThunk, getUserUpdateThunk } from "store/slices/auth/thunk";
import MyModal from "components/MyModal";

const Cart = () => {
  const {
    currentLanguage,
    cartPageProducts,
    currentCurrency,
    shopCartCount,
    shopCartProductAnimation,
    isAuthed,
    profilePageInfo,
  } = useSelector(({ main }) => main);
  const { user } = useSelector(({ auth }) => auth);
  const [products, setProducts] = useState(null);
  const [ameriaModal, setAmeriaModal] = useState(false);
  const [activePaymentId, setActivePaymentId] = useState(19);
  const [pending, setPending] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressName2, setAddressName2] = useState("");
  const [addressType, setAddressType] = useState(0);
  const [thanksModal, setThanksModal] = useState(false);
  const [cashThanksModal, setCashThanksModal] = useState(false);

  const [couponCode, setCouponCode] = useState("");

  const [hvhh, setHvhh] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [legalAddress, setLegalAddress] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressNameError, setAddressNameError] = useState(false);

  const [hvhhError, setHvhhError] = useState(false);
  const [corporateNameError, setCorporateNameError] = useState(false);
  const [legalAddressError, setLegalAddressError] = useState(false);

  const [note, setNote] = useState("");

  const [submitFormTag, setSubmitFormTag] = useState(null);
  const [submitFormTagAmeria, setSubmitFormTagAmeria] = useState(null);
  const [agreementInfo, setAgreementInfo] = useState();

  const [otherUser, setOtherUser] = useState(false);
  const [activeUser, setActiveUser] = useState({});

  const leftBlockRef = useRef();
  const rightBlockRef = useRef();
  const paymentRef = useRef();
  const formRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const mapRef = useRef();
  const [errorCupon, setErrorCupon] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleScroll = (e) => {
  //   if (document.body?.parentNode.scrollTop >= 280) {
  //     rightBlockRef.current.style.position = "absolute";
  //     rightBlockRef.current.style.top =
  //       Math.floor(document.body?.parentNode.scrollTop - 235) + "px";
  //     // rightBlockRef.current.style.right = "8px";

  //     const paymentBlock = paymentRef.current?.getBoundingClientRect();
  //     if (
  //       document.body?.parentNode.scrollTop +
  //         200 -
  //         rightBlockRef.current?.getBoundingClientRect().height >=
  //       paymentBlock.y + paymentBlock.height
  //     ) {
  //       rightBlockRef.current.style.position = "static";
  //       rightBlockRef.current.style.alignSelf = "flex-end";
  //     }
  //   } else {
  //     rightBlockRef.current.style.position = "static";
  //     rightBlockRef.current.style.alignSelf = "auto";
  //     // rightBlockRef.current.style.right = "0px";
  //   }
  // };

  useLayoutEffect(() => {
    if (localStorage.getItem("access_token")) {
      //  dispatch(getProfilePageData())
      dispatch(getUserThunk());
    }
  }, [isAuthed, dispatch]);
  // useLayoutEffect(() => {
  //  if(!profilePageInfo?.addresses?.length){
  //   setFirstName(profilePageInfo?.personal_information?.first_name)
  //   setLastName(profilePageInfo?.personal_information?.last_name)
  //   setEmail(profilePageInfo?.personal_information?.email)
  //   setPhone(profilePageInfo?.personal_information?.phone)
  //  }
  // },[isAuthed,profilePageInfo])

  useLayoutEffect(() => {
    if (
      (Array.isArray(user?.addresses) && !user?.addresses.length) ||
      !Object.keys(user?.addresses || {})?.length
    ) {
      setFirstName(user?.personal_information?.first_name);
      setLastName(user?.personal_information?.last_name);
      setEmail(user?.personal_information?.email);
      setPhone(user?.personal_information?.phone);
    }
  }, [isAuthed, user]);

  useEffect(() => {
    if (Object.keys(user?.addresses || {})?.length) {
      setActiveUser(user?.addresses?.[1]);
    }
  }, [user]);
  const closeOn = () => {
    setAmeriaModal(false)
  }
  // useEffect(() => {
  //   if (window.innerWidth > 1280)
  //     window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     if (window.innerWidth > 1280)
  //       window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    dispatch(getCartSimilarProducts());
    dispatch(getCartPageProducts());
  }, [dispatch]);

  useEffect(() => {
    const productsArray = cartPageProducts?.cart?.map((product) => {
      let onlineSellingPrice = null; // Default value is null
      if (product?.seller_product?.product?.online_price === 1) {
        onlineSellingPrice =
          product?.seller_product?.product?.online_selling_price;
      }
      return {
        id: product.seller_product.id,
        count: product.qty,
        haveInstalling: !!product?.seller_product?.product?.installing_price,
        installing: product.installing_count,
        installingPrice: product?.seller_product?.product?.installing_price,
        image: product?.seller_product?.product.thumbnail_image_source,
        brandName: product?.seller_product?.product?.brand?.name, //
        productName: product?.seller_product?.product.product_name, //
        promoPrice: product?.seller_product?.promo_price, //
        // averagePrice: product?.product?.average_price,
        OnlineSelingPrice: onlineSellingPrice,
        sellingPrice: product?.seller_product?.skus[0]?.selling_price, //
        sticker: product?.seller_product?.product?.sticker, //
        cartId: product.cart_id, //
        gift_images: product?.seller_product?.product?.gift_images, //
      };
    });
    setProducts(productsArray);
  }, [cartPageProducts]);

  useEffect(() => {
    if (Array.isArray(products) && !products?.length) {
      dispatch(
        setCartPageProducts({
          error: 1,
        })
      );
    }
  }, [dispatch, products]);

  useEffect(() => {
    const formSubmit = document.querySelector("#formSubmit form");
    if (formSubmit?.submit) {
      formSubmit.submit();
      setSubmitFormTag(null);
    }
  }, [submitFormTag]);

  useEffect(() => {
    const formSubmit = document.querySelector("#formSubmitAmeria form");
    if (formSubmit?.submit) {
      formSubmit.submit();
      setSubmitFormTagAmeria(null);
    }
  }, [submitFormTagAmeria]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [shopCartProductAnimation, pending]);

  const reqestFormTag = (formTag) => {
    setSubmitFormTag(formTag?.data);
    return null;
  };

  const orderAmeria = () => {
    if (activePaymentId == "ameria") {
      setAmeriaModal(true)
    } else {
      orderFunc();
    }
  }
  const orderFunc = () => {
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setEmailError(!email || !isValidEmail(email));
    setAddressNameError(!addressName);
    setPhoneError(!phone || !isValidPhone(phone));
    let address_info = null;
    let address_id_info = null;


    if (!otherUser) {
      address_info = activeUser?.address;
      address_id_info = activeUser?.id;
    } else {
      address_info = addressName;
    }

    if (!firstName) {
      window.scrollTo({
        top: firstNameRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    } else if (!lastName) {
      window.scrollTo({
        top: lastNameRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    } else if (!email || !isValidEmail(email)) {
      window.scrollTo({
        top: emailRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    } else if (!phone || !isValidPhone(phone)) {
      window.scrollTo({
        top: phoneRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    } else if (!addressName) {
      window.scrollTo({
        top: mapRef.current?.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }

    if (
      (firstName &&
        lastName &&
        email &&
        phone &&
        addressName &&
        (typeof activePaymentId === "number" ||
          typeof activePaymentId === "string")) ||
      !otherUser
    ) {
      setPending(true);
      const guestAddressStoreData = {
        name: firstName || activeUser?.name,
        surname: lastName || activeUser?.surname,
        email: email || activeUser?.email,
        phone: phone || activeUser?.phone,
        phone_2: phone2,
        flat: addressName2,
        note: note,
        addres_type: addressType == 0 ? "Yerevan" : "Regions",
        map_address: address_info ?? addressName,
        address_id_info: address_id_info,
      };
      const updateUser = {
        name: firstName || activeUser?.name,
        surname: lastName || activeUser?.surname,
        email: email || activeUser?.email,
        phone: phone || activeUser?.phone,
        addres_type: addressType == 0 ? "Yerevan" : "Regions",
        map_address: address_info ?? addressName,
        address_id_info: address_id_info,
      };
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      if (
        localStorage.getItem("access_token") &&
        !Object.keys(user?.addresses || {})?.length
      ) {
        dispatch(getUserUpdateThunk(updateUser));
      }

      dispatch(
        guestAddressStore(
          guestAddressStoreData,
          (e) => {
            const sendData = {
              not: note,
              map_address: address_info ?? addressName,
              address_id_info: address_id_info,
              number_of_package: products.length,
              number_of_item: totalCount || 1,
              discount_total: totalPrice - (discountTotalPrice || totalPrice),
              discount: totalPrice - (discountTotalPrice || totalPrice),
              grand_total: totalPrice,
              sub_total: totalPrice,
            };

            if (typeof activePaymentId == "number") {
              sendData.gateway_id = activePaymentId;
              sendData.payment_id = activePaymentId;
            } else if (activePaymentId == "bankTranfer") {
              sendData.payment_id = 20;
              sendData.gateway_id = 20;
              setHvhhError(!hvhh || hvhh.length !== 8);
              setCorporateNameError(!corporateName);
              setLegalAddressError(!legalAddress);
              if (
                !hvhh ||
                hvhh.length !== 8 ||
                !corporateName ||
                !legalAddress
              ) {
                toast(lang[currentLanguage].error_message, {
                  type: "error",
                });
                setPending(false);
                return null;
              }
              sendData.hvhh = hvhh;
              sendData.corporate_name = corporateName;
              sendData.legal_address = legalAddress;
            } else {
              sendData.gateway_id = 18;
              sendData.payment_id = 18;
              if (activePaymentId == "credit") {
                toast("Բանկը ընտրված չէ", {
                  type: "error",
                });
                dispatch(setPending(false));
                return null;
              }
              sendData.credit =
                activePaymentId == "vtb"
                  ? "Vtb"
                  : activePaymentId;
            }

            dispatch(
              postCreditModal(
                sendData,
                (e) => {
                  if (e?.data?.order?.id) {
                    if (activePaymentId === "vtb") {
                      window.location.href = "https://www.vtb.am/am/register/";
                    } else if (activePaymentId === "ameria") {
                      const formData = new FormData();
                      formData.append('order_number', e.data.order.order_number);
                      formData.append('packages', JSON.stringify(e.data.order.packages));
                      formData.append('total', totalPrice);
                      axiosInstance
                        .post(process.env.REACT_APP_SERVER_URL + "ameria-api", formData, {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                          },
                        })
                        .then(res => setSubmitFormTagAmeria(res?.data))

                        .catch(() => setPending(false));
                    }
                    else if (activePaymentId === 1) {
                      setPending(false);
                      setCashThanksModal({
                        order_id: e.data.order.id,
                        order_number: e.data.order.order_number,
                      });
                    } else if (activePaymentId == "bankTranfer") {
                      setPending(false);
                      setCashThanksModal({
                        order_id: e.data.order.id,
                        order_number: e.data.order.order_number,
                      });
                    } else if (activePaymentId === 16) {
                      axiosInstance
                        .get(process.env.REACT_APP_SERVER_URL + "tellcell", {
                          params: {
                            id: e.data.order.id,
                            total: totalPrice,
                          },
                        })
                        .then(reqestFormTag)

                        .catch(() => setPending(false));
                    } else if (activePaymentId === 15) {
                      axiosInstance
                        .get(process.env.REACT_APP_SERVER_URL + "idram", {
                          params: {
                            id: e.data.order.id,
                            total: totalPrice,
                          },
                        })
                        .then(reqestFormTag)

                        .catch(() => setPending(false));
                    } else if (activePaymentId === 17) {
                      axiosInstance
                        .get(
                          process.env.REACT_APP_SERVER_URL + "arca/paymant/5",
                          {
                            params: {
                              id: e.data.order.order_number,
                              total: totalPrice,
                            },
                          }
                        )
                        .then((e) => {
                          if (e.data.fail) {
                            setPending(false);
                            toast(lang[currentLanguage].error_message, {
                              type: "error",
                            });
                          } else {
                            setPending(false);
                            setSubmitFormTag(e.data?.form);
                          }
                        });
                    } else if (activePaymentId === 19) {
                      axiosInstance
                        .get(
                          process.env.REACT_APP_SERVER_URL + "ineco/index10",
                          {
                            params: {
                              id: e.data.order.order_number,
                              total: totalPrice,
                            },
                          }
                        )
                        .then((e) => {
                          if (e.data.fail) {
                            setPending(false);
                            toast(lang[currentLanguage].error_message, {
                              type: "error",
                            });
                          } else {
                            setSubmitFormTag(e.data?.form);
                          }
                        });
                    } else {
                      setPending(false);
                      setAgreementInfo({
                        text: e.data.cred_info.credit_info_text,
                        icon: e.data.cred_info.icon_en,
                        order_id: e.data.order.id,
                        order_number: e.data.order.order_number,
                      });
                    }
                  } else {
                    setPending(false);
                    toast(lang[currentLanguage].error_message, {
                      type: "error",
                    });
                  }
                },
                () => {
                  setPending(false);
                }
              )
            );
          },
          () => {
            setPending(false);
          }
        )
      );
    } else {
      setPending(false);
    }
  };

  const totalPrice = products?.reduce((count, product) => {
    let total = 0;

    if (product?.installing) {
      total +=
        (product.count - product.installing) *
        ((product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice) -
          product?.installingPrice);
      total +=
        product.installing *
        (product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice);
    } else {
      total +=
        product.count *
        (product?.haveInstalling && !product.installing
          ? (product?.promoPrice ||
            product?.OnlineSelingPrice ||
            product?.sellingPrice) - product.installingPrice
          : product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice);
    }
    return count + total;
  }, 0);

  const discountTotalPrice = products?.reduce((count, product) => {
    let total = 0;
    if (product?.installing) {
      total +=
        (product.count - +product.installing) *
        ((product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice) -
          product?.installingPrice);
      total +=
        +product.installing *
        (product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice);
    } else {
      total +=
        product.count *
        (product?.haveInstalling && !product.installing
          ? (product?.promoPrice ||
            product?.OnlineSelingPrice ||
            product?.sellingPrice) - product.installingPrice
          : product?.promoPrice ||
          product?.OnlineSelingPrice ||
          product?.sellingPrice);
    }
    return count + total;
  }, 0);
  const totalCount = products?.reduce(
    (count, product) => count + product.count,
    0
  );

  if (Object.keys(agreementInfo || {}).length && !cashThanksModal)
    return (
      <Agreement
        setPending={setPending}
        fullName={firstName + " " + lastName}
        agreementInfo={agreementInfo}
        activePaymentId={activePaymentId}
        back={() => setAgreementInfo(null)}
        close={() => {
          setAgreementInfo(null);
        }}
        setCashThanksModal={setCashThanksModal}
      />
    );
  const handleCouponApply = async () => {
    try {
      axiosInstance
        .post(process.env.REACT_APP_SERVER_URL + "checkout/coupon-apply", {
          params: {
            login: process.env.REACT_APP_LOGIN,
            password: process.env.REACT_APP_PASSWORD,
            coupon_code: +couponCode,
            shopping_amount: totalPrice,
          },
        })
        .then((response) => {
          if (response.data == "ok") {
            window.location.reload();
          }
        })
        .catch((err) => {
          setErrorCupon(err);
        });
    } catch (error) {
      setErrorCupon(error);
    }
  };
  return (
    <> 
      {ameriaModal ? (
        <MyModal onClose={closeOn} closeSvg={true}>
          <div style={{ textAlign: "center", marginTop: "20px" }}> {langData.modalTextAmeria.text[currentLanguage]}</div>
          <button onClick={()=>orderFunc()} className={styles?.order_complete_button}>{langData?.modalTextAmeria?.buttons[currentLanguage]}</button>
        </MyModal>
      ) : null}

      {pending && <CartLoader />}
      <section
        style={pending ? { display: "none" } : {}}
        className={styles.cart_content_container}>
        <div>
          <div className={styles.cart_checkout_container}>
            <div
              ref={leftBlockRef}
              className={styles.cart_left_block}>
              <div className={styles.cart_products_list}>
                {products?.map((product, index) => {
                  return (
                    <CartProduct
                      key={index}
                      product={product}
                      setProduct={(newState) => {
                        let productsArray = products?.map((item) => {
                          if (item.id === product.id) {
                            return newState;
                          }
                          return item;
                        });

                        setProducts(productsArray);
                      }}
                      deleteProduct={() => {
                        dispatch(
                          setCartPageDelete({
                            id: product.cartId,
                          })
                        );
                        let productsArray = products.filter(
                          (item) => item.id !== product.id
                        );
                        setProducts(productsArray);
                      }}
                    />
                  );
                })}
              </div>
              {!localStorage.getItem("access_token") && <LoginCart />}
              {localStorage.getItem("access_token") &&
                !!Object.keys(user?.addresses || {})?.length && (
                  <PersonalCart
                    profilePageInfo={user}
                    setOtherUser={setOtherUser}
                    activeUser={activeUser}
                    setActiveUser={setActiveUser}
                  />
                )}
              {(!localStorage.getItem("access_token") ||
                !!otherUser ||
                (localStorage.getItem("access_token") &&
                  !Object.keys(user?.addresses || {})?.length)) && (
                  <CartForm
                    ref={formRef}
                    firstNameRef={firstNameRef}
                    lastNameRef={lastNameRef}
                    emailRef={emailRef}
                    phoneRef={phoneRef}
                    mapRef={mapRef}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    phone2={phone2}
                    setPhone2={setPhone2}
                    addressType={addressType}
                    setAddressType={setAddressType}
                    firstNameError={firstNameError}
                    lastNameError={lastNameError}
                    emailError={emailError}
                    phoneError={phoneError}
                    setAddressName={setAddressName}
                    addressName={addressName}
                    addressName2={addressName2}
                    setAddressName2={setAddressName2}
                    addressNameError={addressNameError}
                  />
                )}

              <Payment
                ref={paymentRef}
                activePaymentId={activePaymentId}
                totalPrice={totalPrice}
                setActivePaymentId={setActivePaymentId}
                hvhh={hvhh}
                setHvhh={setHvhh}
                corporateName={corporateName}
                setCorporateName={setCorporateName}
                legalAddress={legalAddress}
                setLegalAddress={setLegalAddress}
                hvhhError={hvhhError}
                corporateNameError={corporateNameError}
                legalAddressError={legalAddressError}
              />
            </div>
            <div
              ref={rightBlockRef}
              className={styles.cart_right_block_check}>
              <span className={styles.cart_checkout_block_title}>
                {lang[currentLanguage].Basket}
              </span>
              <div className={styles.cart_block}>
                <div className={styles.check_promocode_block}>
                  <span className={styles.haveAre_you_promo}>
                    {lang[currentLanguage]["Do you have a promotion?"]}
                  </span>
                  <div className={styles.check_only_memebers}>
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className={`${styles.check_memebers_input} ${errorCupon ? styles.errorInput : ""
                        }`}
                      placeholder={lang[currentLanguage]["Promo code"]}
                    />
                    <button
                      className={styles.send_memb_email_btn}
                      onClick={handleCouponApply}>
                      {lang[currentLanguage].Activate}
                    </button>
                  </div>
                  {errorCupon && (
                    <div style={{ color: "red" }}>
                      {lang[currentLanguage].invalid_coupon}
                    </div>
                  )}
                  <div>
                    <span className={styles.haveAre_you_promo}>
                      {lang[currentLanguage].comment_order
                        .split(" ")
                        .slice(0, 2)
                        .join(" ")}
                    </span>
                    <textarea
                      className={`general_inps textarea_coment_order ${styles.additional_coment_section_area}`}
                      name="coment"
                      id="coment"
                      cols="30"
                      rows="5"
                      placeholder={lang[currentLanguage].comment_order}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}></textarea>
                  </div>
                </div>

                <div className={styles.cart_checkout_block}>
                  <div className={styles.checkout_prouducts_count_block}>
                    <span className={styles.checkout_prouducts_count}>
                      {lang[currentLanguage].Product} ({totalCount})
                    </span>
                    <span className={styles.dash_cart}></span>
                    <span className={styles.checkout_prouducts_price}>
                      {UseCalcPrice(totalPrice, currentCurrency)}
                    </span>
                  </div>

                  <div className={styles.checkout_prouducts_shiping}>
                    <span className={styles.checkout_prouducts_count}>
                      {lang[currentLanguage].Shipment}
                    </span>
                    <span className={styles.dash_cart}></span>
                    <span className={styles.checkout_prouducts_price}>0 ֏</span>
                  </div>
                  <span className={styles.inf_name}>
                    {lang[currentLanguage].shiping_state}
                  </span>
                  <div className={styles.dash_cart}></div>

                  <div className={styles.general_price}>
                    <div className={styles.general_price_block}>
                      <span className={styles.general_price_title}>
                        {lang[currentLanguage].total}
                      </span>
                      <span className={styles.total_price_with_delivery}>
                        {UseCalcPrice(totalPrice, currentCurrency)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.order_complete_button_container}>
              <button
                className={styles.order_complete_button}
                onClick={orderAmeria}>
                {lang[currentLanguage]["Order confirmation"]}
              </button>
            </div>
          </div>

          <CartRelatedProducts />
        </div>
      </section>

      {submitFormTag && (
        <div
          style={{ display: "none" }}
          id="formSubmit"
          dangerouslySetInnerHTML={{ __html: submitFormTag }}></div>
      )}

      <div style={{ display: "none" }} id="formSubmitAmeria"
        dangerouslySetInnerHTML={{ __html: submitFormTagAmeria }}>

      </div>
      {thanksModal && (
        <div className={styles.thanksModalBg}>
          <div className={styles.thanksModal}>
            <Link
              to={"/"}
              className={styles.thanksModalClose}>
              <CloseSvg />
            </Link>
            <img
              className={styles.thanksModalImg}
              src="https://vlv.am/public/img/order_alert.png"
            />
            <span className={styles.thanksModalTitle}>
              {lang[currentLanguage]?.ushadrutyun}
            </span>
            <span className={styles.thanksModalSubTitle}>
              {lang[currentLanguage]?.order_alert_text}
            </span>
            <Link
              to={"/"}
              className={styles.thanksModalBtn}>
              <span>{lang[currentLanguage]?.to_close}</span>
            </Link>
          </div>
        </div>
      )}
      {cashThanksModal && (
        <ThanksModal
          data={{
            ...agreementInfo,
            ...(Object.keys(cashThanksModal || {})?.length
              ? cashThanksModal
              : {}),
            name: firstName,
            surname: lastName,
            email: email,
            phone: phone,
            flat: "",
            address_inp: addressName,
            note: note,
          }}
          qty={totalCount}
          product={products}
          totalPrice={totalPrice}
          show_payment_type={false}
          close={() => {
            navigate("/");
          }}
        />
      )}
    </>
  );
};

export default Cart;
