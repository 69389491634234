import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BanerSlider from "components/BanerSlider";
import { brandThunk, brandCategoryThunk } from "store/slices/brandSlice/thunk";
import styles from "./style.module.css";
import LoadContent from "components/LoadContent";
import langData from "lang/data";
import { selectBrand, selectMain } from "store/selector";
import FromToPage from "components/FromToPage";
import {
  clearBrand,
  clearBrandCategory,
} from "store/slices/brandSlice/brandSlice";
import { setPending } from "store/MainSlice";
import { setCategory } from "store/slices/category/categorySlice";

const Brand = ({WsSlug}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  let { categoryId, brandId } = useParams();
  if(WsSlug){
    // categoryId = WsSlug
    brandId = WsSlug
  }
  
  
  const { brand, brandCategoy, loading, categoryLoading } =
    useSelector(selectBrand);
  const { currentLanguage } = useSelector(selectMain);
  const { searchPageData } = useSelector(({ searchPage }) => searchPage);

  useEffect(() => {
    if (brandId) {
      dispatch(setCategory({}));
      dispatch(clearBrand());
      dispatch(clearBrandCategory());
      dispatch(brandThunk(brandId));
      dispatch(setPending(false));
    }
  }, [brandId, dispatch, pathname]);

  useEffect(() => {
    if (categoryId) {
      dispatch(setCategory({}));
      dispatch(clearBrand());
      dispatch(clearBrandCategory());
      dispatch(brandCategoryThunk(categoryId));
      dispatch(setPending(false));
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearBrand());
      dispatch(clearBrandCategory());
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (
    (!brand?.brand_categories?.length && brandId && !loading) ||
    (!brandCategoy?.sub_categories?.length && categoryId && !categoryLoading)
  ) {
    navigate(
      `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/search-null`
    );
  }

  return (
    <div className="wrapper">
      {loading || categoryLoading ? (
        <LoadContent containerClassName={styles.logLoad} />
      ) : (
        <>
          <FromToPage
            pages={[
              ...(searchPageData?.length
                ? searchPageData?.breadcrumbs?.map((item) => ({
                    value: item?.slug,
                    label: item?.["name_" + currentLanguage],
                  }))
                : []),
              {
                label: !brandId
                  ? brandCategoy?.category?.["name_" + currentLanguage]
                  : brand?.brand_info?.name,
                active: true,
              },
            ]}
          />
          <div className={styles.title}>
            {brandId && (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL + brand?.brand_info?.logo
                }
                className={styles.logo}
              />
            )}
            {!brandId && (
              <span className={styles.titleText}>
                {brandCategoy?.category?.["name_" + currentLanguage]}
              </span>
            )}
            {!!(brandCategoy?.product_count || brand?.product_count) && (
              <span className={styles.titleCount}>
                {brandCategoy?.product_count || brand?.product_count}
              </span>
            )}
          </div>
        </>
      )}
      <div className={styles.brand} >
      {loading ? (
          <LoadContent containerClassName={styles.sliderLoad} />
        ) : (
          <>
            {(!!brand?.brand_page_slider?.length ||
              !!brandCategoy?.category_slider_images?.length) && (
              <div className={styles.sliderbrand}>
                <BanerSlider
                  bannerSliders={
                    brand?.brand_page_slider ||
                    brandCategoy?.category_slider_images
                  }
                  containerStyle={{
                    margin: 0,
                    padding: 0,
                  }}
                  loop
                />
              </div>
            )}
          </>
        )}
        <div className={styles.brandTop}>
          {loading || categoryLoading ? (
            <LoadContent containerClassName={styles.leftLoad} />
          ) : (
            <div className={styles.brandLeft}>
              <div className={styles.brandLeftContainer}>
                <h1>{langData.brand.category[currentLanguage]}</h1>
                <div className={styles.brandLeftList}>
                  {(
                    brand?.brand_categories || brandCategoy?.sub_categories
                  )?.map((item) => (
                    <span
                      key={item}
                      onClick={() => {
                        if (brandId) {
                          navigate(
                            `/category/filter/${item?.slug}?b=${brand?.brand_info?.id}`
                          );
                        } else {
                          navigate(`/category/filter/${item?.slug}`);
                        }
                      }}
                    >
                      {item?.["name_" + currentLanguage]}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          )}
          {loading || categoryLoading ? (
            <LoadContent containerClassName={styles.rightLoad} />
          ) : (
            <div className={styles.brandRight}>
              {(brand?.brand_categories || brandCategoy?.sub_categories)?.map(
                (item) => (
                  <div key={item?.id} className={styles?.brandRightItem}>
                    <div
                      className={styles.brandRightItemTop}
                      onClick={() => {
                        if (brandId) {
                          navigate(
                            `/category/filter/${item?.slug}?b=${brand?.brand_info?.id}`
                          );
                        } else {
                          navigate(`/category/filter/${item?.slug}`);
                        }
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          item?.category_image?.image
                        }
                        alt="product"
                      />
                    </div>
                    <div className={styles.brandRightItemBottom}>
                      <span
                        onClick={() =>
                          navigate(`/category/filter/${item?.slug}`)
                        }
                      >
                        {item?.["name_" + currentLanguage]}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>

        {brand?.brand_info && (
          <div className={styles.brandBottom}>
            {loading ? (
              <LoadContent containerClassName={styles.bottomLoad} />
            ) : (
              <>
                {!!brand?.brand_info?.brand_image && (
                  <div className={styles.brandBottomTop}>
                    {!["<p><br></p>"]?.includes(
                      Object.values(brand?.brand_info)[12]
                    ) && (
                      <div className={styles.brandBottomTopItemLeft}>
                        <div className={styles.brandBottomTopLeftContent}>
                          <h3>
                            {
                              brand?.brand_info?.[
                                "brand_title_section_" + currentLanguage
                              ]
                            }
                          </h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                brand?.brand_info?.[
                                  "brand_descr_section_" + currentLanguage
                                ],
                            }}
                          ></p>
                        </div>
                      </div>
                    )}
                    {!!brand?.brand_info?.brand_image && (
                      <div className={styles.brandBottomTopItemRight}>
                        <div className={styles.brandBottomTopItemRightContent}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              brand?.brand_info?.brand_image
                            }
                            alt="compony"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      brand?.brand_info?.[
                        "brand_descr_section2_" + currentLanguage
                      ],
                  }}
                  className={styles.brandBottomBottom}
                ></div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Brand;
