export const DATA = [
    {
      label_en: "Price (High to Low)",
      label_ru: "Цена : макс - мин",
      label_hy: "Գին՝ բաձրից ցածր",
      value: "high_to_low",
    },
    {
      label_en: "Price (Low to High)",
      label_ru: "Цена :  мин - макс",
      label_hy: "Գին` ցածրից բարձր",
      value: "low_to_high",
    },
    {
      label_en: "Name (Z to A)",
      label_ru: "Названию : от Я до А",
      label_hy: "Անուն` Ֆ-ից Ա",
      value: "alpha_desc",
    },
    {
      label_en: "Name (A to Z)",
      label_ru: "Названию : от А до Я",
      label_hy: "Անուն` Ա-ից Ֆ",
      value: "alpha_asc",
    },
    // {
    //   label_en: "Old",
    //   label_ru: "Давний",
    //   label_hy: "Հին",
    //   value: "old",
    // },
    // {
    //   label_en: "New",
    //   label_ru: "Новое",
    //   label_hy: "Նոր",
    //   value: "new",
    // },
  ];
  