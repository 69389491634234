import React, { useRef } from "react";
import styles from "./style.module.css";
import "./style.css";
import HeartSvg from "../../assets/SVG/HeartSvg";
import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import CompareSvg from "../../assets/SVG/CompareSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardStore,
  addCompare,
  addCompares,
  addFavorites,
  addShopCartCount,
  addWishList,
  removeCompares,
  removeFavorites,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setShopCartProductAnimation,
} from "../../store/MainSlice";
import checkBase64 from "../../helpers/checkBase64";
import { credit36Month } from "../../helpers/creditCalculator";
import langData from "../../lang/data";
import GiftSvg from "../../assets/SVG/GiftSvg";
import UseCalcPrice from "../../helpers/UseCalcPrice";
import { Link } from "react-router-dom";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import CardBackground from "assets/SVG/CardBackground";
import lang from "lang/lang";
import useWindowSize from "components/useWindowSize";
import { setOpenOrderModal } from "components/OrderModal/state/orderModalSlice";

export default function ProductCard({
  product,
  cartChangeStyle,
  flexContainer,
  containerStyle,
  refresh = false,
  onTouchStart,
  onTouchEnd,
}) {
  const dispatch = useDispatch();
  const productImageRef = useRef();
  const { width: windowWidth } = useWindowSize();

  const { currentLanguage, currentCurrency, favorites, compares } = useSelector(
    ({ main }) => main
  );

  const handleHeartClick = () => {
    if (
      (product?.product?.gallary_images_api?.[0] ||
        product?.product?.thumbnail_image_source) &&
      !favorites.includes(product.id)
    ) {
      const rect = productImageRef.current?.getBoundingClientRect();
      if (rect) {
        const { top, left } = rect;
        dispatch(
          setHeartProductAnimation({
            image:
              product?.product?.gallary_images_api?.[0]?.images_source ||
              product?.product?.thumbnail_image_source,
            position: { top, left },
          })
        );
      }
    }
    if (!favorites.includes(product.id)) {
      dispatch(addFavorites(product.id));
    } else {
      dispatch(removeFavorites(product.id));
    }
    dispatch(
      addWishList({
        seller_product_id: product.id,
      })
    );
  };

  const handleComparClick = () => {
    if (
      (product?.product?.gallary_images_api?.[0] ||
        product?.product?.thumbnail_image_source) &&
      !compares.includes(product?.skus?.[0]?.id)
    ) {
      const { top, left } = productImageRef.current?.getBoundingClientRect();
      dispatch(
        setCompareProductAnimation({
          image:
            product?.product?.gallary_images_api?.[0]?.images_source ||
            product?.product?.thumbnail_image_source,
          position: {
            top,
            left,
          },
        })
      );
    }
    if (!compares.includes(product?.skus?.[0]?.id)) {
      dispatch(addCompares(product?.skus?.[0]?.id));
    } else {
      dispatch(removeCompares(product?.skus?.[0]?.id));
    }

    dispatch(
      addCompare({
        product_sku_id: product?.skus?.[0].id,
        data_type: product.product.product_type,
      })
    );
  };
  const handleBtnClick = () => {
    if (product?.product?.li_product_id || product?.li_product_id) {
      if (
        product?.product?.gallary_images_api?.[0] ||
        product?.product?.thumbnail_image_source
      ) {
        const { top, left } = productImageRef?.current?.getBoundingClientRect();

        dispatch(
          setShopCartProductAnimation({
            image:
              product?.product?.gallary_images_api?.[0]?.images_source ||
              product?.product?.thumbnail_image_source,
            position: {
              top,
              left,
            },
          })
        );
      }
      dispatch(
        addShopCartCount(
          +product?.promo_price ||
            +(
              product?.product?.online_price &&
              product?.product?.online_selling_price
            ) ||
            +product?.skus[0]?.selling_price ||
            0
        )
      );
      dispatch(
        addCardStore({
          installing: !!product?.product?.installing_price,
          installing_count: +!!product?.product?.installing_price,
          installing_qty: +!!product?.product?.installing_price,
          price:
            +product?.promo_price ||
            +(
              product?.product?.online_price &&
              product?.product?.online_selling_price
            ) ||
            +product?.skus[0]?.selling_price,
          qty: 1,
          product_id: product?.seller_product_sku_id || product?.skus?.[0]?.id,
          seller_id: 1,
          shipping_method_id: 0,
          type: "product",
          is_buy_now: "no",
        })
      );
      if (refresh) {
        window.location.reload();
      }
    } else {
      dispatch(
        setOpenOrderModal({
          open: true,
          id: product.id,
        })
      );
    }
  };

  const calculateFinalPrice = (price) => {
    if (
      (product?.product?.categories?.[0]?.installation !== 0 &&
        product?.product?.categories?.[0]?.installing === 0) ||
      (product?.categories?.[0]?.installation !== 0 &&
        product?.categories?.[0]?.installing === 0)
    ) {
      return (
        price - product?.product?.installing_price || product?.installing_price
      );
    }
    return price;
  };

  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      ref={productImageRef}>
      <CardBackground width={windowWidth} />
      <div className={styles.headerRight}>
        <HeartSvg
          className={styles.heartSvg}
          onClick={handleHeartClick}
          active={favorites.includes(product?.id)}
        />
        <div
          className={styles.compareBtn}
          onClick={handleComparClick}>
          <CompareSvg active={compares.includes(product?.skus?.[0]?.id)} />
        </div>
      </div>
      <div className={styles.headerLeft}>
        {product?.product?.sticker?.length ? (
          <img
            className={styles.stickerImg}
            src={
              process.env.REACT_APP_STORAGE_URL +
              product?.product?.sticker?.[0]?.["image_" + currentLanguage]
            }
            alt={""}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.maxHeight = "20px";
              currentTarget.src = require("../../assets/defaultImg.png");
            }}
          />
        ) : null}

        {windowWidth >= 768 &&
        Object.keys(product?.product?.guaranty || {}).length ? (
          <div className={styles.guarantee}>
            <span>{lang[currentLanguage].guaranty}</span>
            <span>{product?.product?.guaranty[currentLanguage]}</span>
          </div>
        ) : null}
        {product?.product?.gift_images?.length ? (
          <div className={styles.giftBox}>
            <GiftSvg />
            <div className={styles.giftHovered}>
              {product?.product?.gift_images?.map((item, ind) => (
                <div
                  className={styles.giftHoveredMain}
                  key={ind}>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/Product/${item?.product_id}`}>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL + (item.image || item)
                      }
                      className={styles.giftHoveredImg}
                      alt="gift"
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>

      <div className={styles.InCardSlider}>
        <Swiper
          slidesPerView={1}
          pagination={{ clickable: true }}
          modules={[Pagination, Navigation]}
          loop={false}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          className={styles.product__wrapper + " paginate"}>
          {!product?.product?.gallary_images_api?.length ? (
            <SwiperSlide>
              <Link
                to={`${
                  currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/Product/${product?.id}`}>
                <img
                  style={{
                    width: "100%",
                    height: "171px",
                    objectFit: "contain",
                    paddingBottom: "7px",
                  }}
                  alt={"noImage"}
                  src={require("../../assets/defaultImg.png")}
                />
              </Link>
            </SwiperSlide>
          ) : (
            (product?.media_ids || product?.product?.media_ids)
              ?.split(",")
              ?.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/Product/${product?.id}`}>
                    <img
                      style={{
                        width: "100%",
                        height: "171px",
                        objectFit: "contain",
                        paddingBottom: "7px",
                      }}
                      alt={
                        product?.product?.brand?.name +
                        " " +
                        product?.product?.product_name
                      }
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        (
                          product?.gallary_images_api ||
                          product?.product?.gallary_images_api
                        )?.find((gallery) => gallery?.media_id === +item)
                          ?.images_source
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../assets/defaultImg.png");
                      }}
                    />
                  </Link>
                </SwiperSlide>
              ))
          )}
        </Swiper>
      </div>
      {/*PriceBlock Start*/}
      <div className={styles.descContainer}>
        <Link
          to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/brand/${
            product?.product?.brand?.slug
          }/category`}>
          <img
            className={styles.brandImg}
            src={
              checkBase64(product?.product?.brand?.logo)
                ? "data:image/png;base64," + product?.product?.brand?.logo
                : process.env.REACT_APP_STORAGE_URL +
                  product?.product?.brand?.logo
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.style.maxHeight = "20px";
              currentTarget.src = require("../../assets/defaultImg.png");
            }}
            alt={
              product?.product?.brand?.name +
              " " +
              product?.product?.product_name
            }
          />
        </Link>
        <Link
          to={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/Product/${product?.id}`}>
          <div className={styles.categoryName}>
            {product?.categories?.[0]?.["name_" + currentLanguage] ||
              product?.product?.categories?.[0]?.["name_" + currentLanguage]}
          </div>
          <p className={styles.productName}>
            {product?.product?.brand?.name +
              " " +
              product?.product?.product_name}
          </p>
          {product?.product?.cashback ? (
            <div className={styles.cashbackContainer}>
              <span className={styles.cashbackText}>Cashback</span>
              <span
                className={styles.cashbackPrice}
                style={{ marginLeft: "5px" }}>
                {UseCalcPrice(product?.product?.cashback, currentCurrency)}
              </span>
            </div>
          ) : null}
  

          {!product?.promo_price &&
          !(
            product?.product?.online_price &&
            product?.product?.online_selling_price
          ) ? (
            <p className={styles.price}>
              {UseCalcPrice(
                calculateFinalPrice(
                  product?.product?.average_price ||
                    product?.skus[0]?.selling_price
                ),
                currentCurrency
              )}
            </p>
          ) : (
            <div className={styles.promoPriceContainer}>
              <p className={styles.newPrice}>
                {UseCalcPrice(
                  calculateFinalPrice(
                    product?.promo_price ||
                      (product?.product?.online_price &&
                        product?.product?.online_selling_price)
                  ),
                  currentCurrency
                )}
              </p>
              <div className={styles.oldPriceContainer}>
                <p className={styles.oldPrice}>
                  {UseCalcPrice(
                    product?.skus[0]?.selling_price,
                    currentCurrency
                  )}
                </p>

                <span className={styles.line} />
              </div>
            </div>
          )}
                  {credit36Month(
            product?.promo_price ||
              (product?.online_price && product?.online_selling_price) ||
              product?.skus[0]?.selling_price
          ) ? (
            <div className={styles.priceMounth}>
              {UseCalcPrice(
                credit36Month(
                  product?.promo_price ||
                    (product?.product?.online_price &&
                      product?.product?.online_selling_price) ||
                    product?.skus[0]?.selling_price
                ),
                currentCurrency
              )}{" "}
              / {langData.month[currentLanguage]}
            </div>
          ) : (
            <div style={{ height: "24px" }} />
          )}
        </Link>

        <div
          className={styles.cardBtn}
          onClick={handleBtnClick}>
          <ShopCartSvg className={styles.cardBtnSvg} />
        </div>
      </div>
    </div>
  );
}