import React, { useRef, useState, useEffect } from "react";
import styles from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function Sliders() {
  const [showBtns, setShowBtns] = useState(false);
  const { headerSliders, headerBaners } = useSelector(({ home }) => home);
  const { currentLanguage } = useSelector(({ main }) => main);
  const swiperRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return (
    <div className="wrapper" style={{ marginBottom: windowWidth < 600 ? '10px' : " " }}>
      <div className={styles.container}>
        <div
          className={styles.leftContent}
          onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
          onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}>
          {!headerSliders?.length ? (
            <LoadContent containerClassName={styles.leftLoadContent} />
          ) : (
            <>
              {window.innerWidth > 768 && (
                <>
                  <div
                    className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                    style={showBtns ? { display: "flex" } : { display: "none" }}
                    onClick={() => swiperRef.current?.slidePrev()}>
                    <SliderNextArrowSvg />
                  </div>
                  <div
                    className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                    style={showBtns ? { display: "flex" } : { display: "none" }}
                    onClick={() => swiperRef.current?.slideNext()}>
                    <SliderNextArrowSvg />
                  </div>
                </>
              )}

              <Swiper
                loop
                autoplay
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                grabCursor={true}
                centeredSlides={true}
                className={styles.leftSlider}>
                {headerSliders.map((item, index) => (
                  <SwiperSlide key={index}>
                    <a
                      href={item?.href}
                      style={{ display: "block" }}>
                      <img
                        className={styles.leftSliderImg}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          item["slider_image_" + currentLanguage]
                        }
                        type="image/webp"
                        alt="VLV"
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </>
          )}
        </div>
        <div className={styles.rightContent}>
          <div className={styles.rightSlider}>
            {!headerBaners?.Baner1.length ? (
              <LoadContent containerClassName={styles.leftLoadContent} />
            ) : (
              <a
                href={
                  process.env.REACT_APP_SERVER_URL +
                  headerBaners?.Baner1?.[0]?.href.slice(
                    1,
                    headerBaners?.Baner1?.[0]?.href.length
                  )
                }>
                <img
                  className={styles.leftLoadContent}
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    headerBaners?.Baner1?.[0]?.["image_" + currentLanguage]
                  }
                  alt="VLV"
                />
              </a>
            )}
          </div>
          <div className={styles.rightSlider}>
            {!headerBaners?.Baner2.length ? (
              <LoadContent containerClassName={styles.leftLoadContent} />
            ) : (
              <Swiper
                loop
                autoplay
                modules={[Autoplay]}>
                {headerBaners?.Baner2.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ width: "auto", minWidth: "none" }}>
                    <a href={item.href}>
                      <img
                        className={styles.rightSliderImg}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          item["image_" + currentLanguage]
                        }
                        alt="VLV"
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>

      </div>
      {/* <div style={{ marginTop: windowWidth < 600 ? '8px' : "30px" }} >
        <img src="https://vlv.am/black.gif" alt="black friday" />
      </div> */}
    </div>
  );
}
