export const longestDigitsPrefix = (inputString) => {
    if (!inputString) return "";

    const digitsArray = [];
    for (let i = 0; i < inputString.length; i++) {
        if (!isNaN(+inputString[i])) {
            digitsArray.push(inputString[i]);

            if (isNaN(+inputString[i + 1])) {
                break;
            }
        }
    }

    return digitsArray.join("");
};

export const setIndex = (array) => {
    return array?.map((item, index) => {
        return {
            ...item,
            index: index,
        };
    });
};

export const getSubarrayByIndexValue = (array, minValue, maxValue) => {
    return array.slice(minValue.index, maxValue.index + 1);
};

export const sortArrayByValueEn = (array) => {
    return [...array].sort((a, b) => {
        const aValue = parseInt(longestDigitsPrefix(a.value_en).trim(), 10);
        const bValue = parseInt(longestDigitsPrefix(b.value_en).trim(), 10);
        return aValue - bValue;
    });
};
