import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./style.module.css";
import LoadContent from "components/LoadContent";
import langData from "lang/data";
import { selectBrand, selectMain } from "store/selector";
import FromToPage from "components/FromToPage";
import { getFeatureCategories } from "../../../../store/HomeSlice";
import { categories } from "store/WsSearchSlice";

const SearchCategory = () => {
    const [image, setImage] = useState(false);
    const location = useLocation();
    const WSsearchInfo = location?.state?.similar;
    const category = location?.state?.category;
    const { featureCategories } = useSelector(({ home }) => home);
    const { categoriesWs } = useSelector(
        ({ WsSearchSlice }) => WsSearchSlice
    );
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentLanguage } = useSelector(selectMain);

    const [loading, setLoading] = useState(false);
    let allCategory;
    
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(getFeatureCategories());
            setLoading(false);
        };
        fetchData();
    }, [dispatch]);
    useEffect(() => {
        if (!WSsearchInfo?.length) {
            setImage(true);
            setLoading(true);
        } else {
            setImage(false)
            setLoading(false);
        }
    }, [WSsearchInfo,searchParams]);
    if (WSsearchInfo?.length) {
        if (category) {
            allCategory = [category, ...WSsearchInfo];
        } else {
            allCategory = WSsearchInfo;
        }
    } else { 
        const urlParams = new URLSearchParams(window.location.search);
        const encodedCategoryIds = urlParams.get('ct');
        if(!encodedCategoryIds){
            allCategory = featureCategories; 
        } 
    }

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const encodedCategoryIds = urlParams.get('ct');
        if (encodedCategoryIds) {
            try {
                const categoryIds = JSON?.parse(atob(encodedCategoryIds));
                if (!categoriesWs?.length) {
                    setImage(false)
                    setLoading(false);
                    dispatch(categories(categoryIds));
                }
            } catch (error) {
                navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/search-null`);
            }
        }
    }, [currentLanguage,searchParams])
     
    
    if(categoriesWs?.categories?.length){
      
        allCategory = categoriesWs?.categories; 
    }
    
    return (
        <div className="wrapper">
            {loading ? (
                <LoadContent containerClassName={styles.logLoad} />
            ) : (
                <>
                    <FromToPage type={true} />
                    <div className={styles.title}>

                    </div>
                </>
            )}
            <div className={styles.brand} >
                <div className={styles.brandTop}>
                    {loading ? (
                        <LoadContent containerClassName={styles.leftLoad} />
                    ) : (
                        <div className={styles.brandLeft}>
                            <div className={styles.brandLeftContainer}>
                                <h1>{langData.brand.category[currentLanguage]}</h1>
                                <div className={styles.brandLeftList}>
                                    {allCategory?.map((item) => (
                                        <span
                                            key={item}
                                            onClick={() =>
                                                navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                                                }/category/filter/${item?.slug}`)
                                            }
                                        >
                                            {item?.["name_" + currentLanguage]}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {loading ? (
                        <LoadContent containerClassName={styles.rightLoad} />
                    ) : (
                        <div className={styles.brandRight}>
                            {allCategory?.map(
                                (item) => (
                                    <div key={item?.id} className={styles?.brandRightItem}>
                                        <div className={styles.brandRightItemTop}
                                            onClick={() =>
                                                navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                                                    }/category/filter/${item?.slug}`)
                                            }
                                        >
                                            <img
                                                src={
                                                    process.env.REACT_APP_STORAGE_URL +
                                                    (image ? item?.category_image?.image : item?.image?.image)
                                                }
                                                alt="product"
                                            />
                                        </div>
                                        <div className={styles.brandRightItemBottom}>
                                            <span
                                                onClick={() =>
                                                    navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                                                        }/category/filter/${item?.slug}`)
                                                }
                                            >
                                                {item?.["name_" + currentLanguage]}
                                            </span>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchCategory;
