import React, { memo, useRef, useState } from "react";
import styles from "./style.module.css";
import ArmFlagSvg from "../../../../assets/SVG/ArmFlagSvg";
import USAFlagSvg from "../../../../assets/SVG/USAFlagSvg";
import RuFlagSvg from "../../../../assets/SVG/RuFlagSvg";
import ButtonArrowSvg from "../../../../assets/SVG/ButtonArrowSvg";
import DropDown from "../../../DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  getConvertRate,
  postLang,
  setCurrentCurrency,
} from "../../../../store/MainSlice";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";
import langData from "../../../../lang/data";
import { useNavigate } from "react-router-dom";

const languagesData = [
  {
    label: "Հայերեն",
    value: "hy",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Русский",
    value: "ru",
  },
];
const currencyData = [
  {
    label: "(֏) Դրամ",
    value: "amd",
    currency: "֏",
    convertRate: 1,
    id: 121,
  },
  {
    label: "($) Dollars",
    value: "usd",
    currency: "$",
    convertRate: null,
    id: 2,
  },
  {
    label: "(руб) Рубли",
    value: "rub",
    currency: "руб",
    convertRate: null,
    id: 87,
  },
];

export default memo(function Currency({ shortBtn = false }) {
  const [opened, setOpened] = useState(false);
  const { currentLanguage, currentCurrency } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const languageFlags = {
    hy: <ArmFlagSvg />,
    en: <USAFlagSvg />,
    ru: <RuFlagSvg />,
  };
  const containerRef = useRef();

  UseOutSideClick(containerRef, () => setOpened(false));

  return (
    <div className={styles.container} ref={containerRef}    style={opened ? { zIndex: 9999 } : undefined}>
      <div
        className={`${styles.btn} ${shortBtn && styles.shortBtn}`}
        onClick={() => setOpened(!opened)}
      >
        <div className={styles.langFlag}>{languageFlags[currentLanguage]}</div>
        <span style={{ fontSize: "14px" }}>/</span>
        <span className={styles.btnCurrencyText}>{currentCurrency.value}</span>
        <ButtonArrowSvg />
      </div>
      {opened && (
        <div className={styles.openedContainer} style={{zIndex:100000}}>
          <p>{langData.header.langugae[currentLanguage]}</p>
          <DropDown
            active={
              languagesData?.find((e) => e.value === currentLanguage)?.label
            }
            data={languagesData}
            setActive={(item) => {
              if (item.value !== currentLanguage) {
                const urlPathsArray = window.location.pathname
                  ?.split("/")
                  ?.filter((e) => !!e);
                dispatch(postLang(item.value));
                if (
                  (item.value === "hy" || item.value === "am") &&
                  (urlPathsArray[0] === "ru" || urlPathsArray[0] === "en")
                ) {
                  urlPathsArray?.shift();
                } else if (
                  urlPathsArray[0] === "ru" ||
                  urlPathsArray[0] === "en"
                ) {
                  urlPathsArray[0] = item.value;
                } else {
                  urlPathsArray?.unshift(item.value);
                }

                navigate(
                  "/" + urlPathsArray.join("/") + window.location.search
                );
              }
            }}
          />
          <p>{langData.header.сurrency[currentLanguage]}</p>
          <DropDown
            active={
              currencyData.find((e) => e.value === currentCurrency.value)?.label
            }
            data={currencyData}
            setActive={(item) => {
              if (!item.convertRate) {
                dispatch(getConvertRate(item.id));
              }
              dispatch(setCurrentCurrency(item));
            }}
          />
        </div>
      )}
    </div>
  );
});
