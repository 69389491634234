import React from "react";

export default function BackSearchSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
            <path d="M6.29468 12.6562H23.1697C23.3935 12.6562 23.6081 12.7451 23.7663 12.9034C23.9245 13.0616 24.0134 13.2762 24.0134 13.5C24.0134 13.7238 23.9245 13.9384 23.7663 14.0966C23.6081 14.2549 23.3935 14.3438 23.1697 14.3438H6.29468C6.0709 14.3438 5.85629 14.2549 5.69806 14.0966C5.53982 13.9384 5.45093 13.7238 5.45093 13.5C5.45093 13.2762 5.53982 13.0616 5.69806 12.9034C5.85629 12.7451 6.0709 12.6562 6.29468 12.6562Z" fill="black" />
            <path d="M6.75561 13.5L13.7537 20.4964C13.9121 20.6548 14.0011 20.8697 14.0011 21.0937C14.0011 21.3178 13.9121 21.5327 13.7537 21.6911C13.5952 21.8496 13.3804 21.9386 13.1563 21.9386C12.9322 21.9386 12.7174 21.8496 12.5589 21.6911L4.96517 14.0974C4.88659 14.019 4.82425 13.9259 4.78172 13.8234C4.73918 13.7209 4.71729 13.611 4.71729 13.5C4.71729 13.389 4.73918 13.2791 4.78172 13.1766C4.82425 13.0741 4.88659 12.981 4.96517 12.9026L12.5589 5.30887C12.7174 5.15044 12.9322 5.06143 13.1563 5.06143C13.3804 5.06143 13.5952 5.15044 13.7537 5.30887C13.9121 5.46731 14.0011 5.68219 14.0011 5.90625C14.0011 6.13031 13.9121 6.34519 13.7537 6.50362L6.75561 13.5Z" fill="black" />
        </svg>
    );
}
