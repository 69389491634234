import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSales } from "../../store/HomeSlice";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";
import LoadContent from "../../components/LoadContent";
import { Link } from "react-router-dom";
import { salesDetailsFetch } from "../../store/SalesDetails/salesDetailsFetch";
import lang from "../../lang/lang";
const Sales = () => {
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(({ main }) => main);
  const { sales } = useSelector(({ home }) => home);

  useEffect(() => {
    dispatch(getSales());
  }, [dispatch]);

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: lang[currentLanguage].promotions,
            value: "/sales",
            active: true,
          },
        ]}
      />
      <div className={styles.promotionsBlock}>
        {sales?.length
          ? sales?.map((sale) => {
              let imageUri = sale.gallary_images?.find(
                (elm) => elm.lang === currentLanguage
              )?.image;

              if (sale.view == 4) {
                return (
                  <a
                    key={sale.id}
                    href={`https://vlv.am/sales/${sale.id}`}
                    className={styles.newsProdBlck}>
                    <div className={styles.promImg}>
                      <img
                        src={process.env.REACT_APP_STORAGE_URL + imageUri}
                        alt={""}
                      />
                    </div>
                    <div className={styles.name}>
                      {sale["public_name_" + currentLanguage]}
                    </div>
                  </a>
                );
              }

              return (
                <Link
                  key={sale.id}
                  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/sale/${sale.id}/${sale.view}`}
                  onClick={() => dispatch(salesDetailsFetch(sale.id))}
                  className={styles.newsProdBlck}>
                  <div className={styles.promImg}>
                    <img
                      src={process.env.REACT_APP_STORAGE_URL + imageUri}
                      alt={""}
                    />
                  </div>
                  <div className={styles.name}>
                    {sale["public_name_" + currentLanguage]}
                  </div>
                </Link>
              );
            })
          : Array.from({ length: 5 }).map(() => (
              <div className={styles.newsProdBlck}>
                <LoadContent
                  containerStyle={{
                    maxWidth: "100%",
                    maxHeight: "240px",
                    width: "156px",
                    height: "240px",
                    margin: "0 auto",
                  }}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default Sales;
