import React, { useEffect } from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";
import { getMainInfo } from "../../store/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import AboutSlider from "./components/slider";
import Blog from "../Home/components/Blog";
import Sales from "../Home/components/Sales";
import { getAbouUsPageData } from "../../store/MainSlice";
import langData from "../../lang/data";
import LoadContent from "../../components/LoadContent";
import { Link } from "react-router-dom";

export default function AboutUs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMainInfo());
    dispatch(getAbouUsPageData());
  }, []);
  const { currentLanguage, aboutUsPageData } = useSelector(({ main }) => main);

  const saleBackgroundColor = "inherit";
  const banksAboutList = [
    {
      id: 1,
      img: "https://vlv.am/public/img/ineco.png",
    },
    // {
    //   id: 2,
    //   img: "https://vlv.am/public/img/unii.png",
    // },
    {
      id: 3,
      img: "https://vlv.am/public/img/idram.svg",
    },
    {
      id: 4,
      img: "https://vlv.am/public/img/aeb.png",
    },
    // {
    //   id: 5,
    //   img: "https://vlv.am/public/img/evoca.png",
    // },
    // {
    //   id: 6,
    //   img: "https://vlv.am/public/img/norman.png",
    // },
    {
      id: 7,
      img: "https://vlv.am/public/img/ameria.svg",
    },
    {
      id: 8,
      img: "https://vlv.am/public/img/converse.png",
    },
    {
      id: 9,
      img: "https://vlv.am/public/img/arcax.png",
    },
    {
      id: 10,
      img: "https://vlv.am/public/img/vtb.png",
    },
    // {
    //   id: 11,
    //   img: "https://vlv.am/public/img/sef.png",
    // },
    {
      id: 12,
      img: "https://vlv.am/public/img/acba.png",
    },
  ];

  return (
    <>
      <div className="wrapper">
        <FromToPage
          pages={[
            {
              label: langData.header.menu.About_us[currentLanguage],
              value: "/about-us",
              active: true,
            },
          ]}
        />
        <div className={styles.aboutUsBlock}>
          <div className={styles.creditBanner}>
            {!aboutUsPageData ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBaner}
              />
            ) : (
              <img
                className={styles.creditBannerImg}
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  aboutUsPageData?.baner["image_" + currentLanguage]
                }
                alt="img"
              />
            )}
          </div>
          {!aboutUsPageData ? (
            <LoadContent containerClassName={styles.LoadContentdescript} />
          ) : (
            <element
              dangerouslySetInnerHTML={{
                __html: aboutUsPageData?.about_us["block_1_" + currentLanguage],
              }}></element>
          )}
        </div>
        <div className={styles.brendsBlock}>
          {aboutUsPageData?.brands?.map((banner) =>
            !aboutUsPageData ? (
              <LoadContent containerClassName={styles.LoadContentBrend} />
            ) : (
              <Link
                to={`/brand/${banner.slug}${
                  currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/category`}
                key={banner.id}
                className={styles.brend}>
                <img
                  src={process.env.REACT_APP_STORAGE_URL + banner.logo}
                  className={styles.banner}
                  alt={banner.name}
                />
              </Link>
            )
          )}
          <Link
            className={styles.moreBtn}
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/brandSeeAll
            `}>
            <element
              dangerouslySetInnerHTML={{
                __html:
                  aboutUsPageData?.about_us[
                    "block_6_link_text_" + currentLanguage
                  ],
              }}></element>
          </Link>
        </div>
        <div className={styles.aboutSecondBanner}>
          <div className={styles.secondBannerDescrip}>
            <element
              dangerouslySetInnerHTML={{
                __html: aboutUsPageData?.about_us["block_3_" + currentLanguage],
              }}></element>
          </div>
          <div className={styles.aboutDescriptionRight}>
            {!aboutUsPageData ? (
              <LoadContent
                containerClassName={styles.LoadContentaboutDescriptionRight}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  aboutUsPageData?.about_us?.[
                    "block_3_image_" + currentLanguage
                  ]
                }
                alt={""}
              />
            )}
          </div>
        </div>
        <div className={styles.aboutDescriptionSecond}>
          <span>
            <element
              dangerouslySetInnerHTML={{
                __html: aboutUsPageData?.about_us["block_4_" + currentLanguage],
              }}></element>
          </span>
        </div>
        <AboutSlider
          sliderImageList={
            aboutUsPageData?.about_us["slides_" + currentLanguage]
          }
        />
        <div className={styles.aboutDescription}>
          <element
            dangerouslySetInnerHTML={{
              __html: aboutUsPageData?.about_us["block_5_" + currentLanguage],
            }}></element>
        </div>
        <div className={styles.teamMembersBlock}>
          <div className={styles.teamMemberAbout}>
            <span className={styles.teamMembersBlockTitle}>
              <element
                dangerouslySetInnerHTML={{
                  __html:
                    aboutUsPageData?.about_us[
                      "block_6_title_" + currentLanguage
                    ],
                }}></element>
            </span>
            <a
              href="/job"
              className={styles.moreAboutMembers}>
              <element
                dangerouslySetInnerHTML={{
                  __html:
                    aboutUsPageData?.about_us[
                      "block_6_link_text_" + currentLanguage
                    ],
                }}></element>
            </a>
          </div>
          <div className={styles.moreAboutMembersImg}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                aboutUsPageData?.about_us["block_6_image_" + currentLanguage]
              }
              alt={""}
            />
          </div>
        </div>
        <div className={styles.aboutDescriptionSecond}>
          <element
            dangerouslySetInnerHTML={{
              __html: aboutUsPageData?.about_us["block_7_" + currentLanguage],
            }}></element>
        </div>
        <div className={styles.banksAbout}>
          {banksAboutList.map((item) => (
            <div
              key={item.id}
              className={styles.banksAboutItem}>
              <img
                src={item.img}
                alt={""}
              />
            </div>
          ))}
        </div>
        <div className={styles.aboutDescriptionSecond}>
          <element
            dangerouslySetInnerHTML={{
              __html: aboutUsPageData?.about_us["block_8_" + currentLanguage],
            }}></element>
        </div>
      </div>
      <Blog />
      <Sales saleBackgroundColor={saleBackgroundColor} />
    </>
  );
}
